import cx from 'classnames'

import TinyMicroplate from '~/components/TinyMicroplate'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import {
  DOUBLING_RATE_BEST_BOUNDS,
  DOUBLING_RATE_GOOD_BOUNDS,
  HitpickingValues,
  KO_SCORE_HETEROZYGOUS_BOUNDS,
  KO_SCORE_HOMOZYGOUS_BOUNDS,
  getPlateAndWellId,
} from '../charts/hitpickingData'
import { CleCulturePopover } from '../clePlates/CleCulturePopover'
import { ClePhase } from '../clePlates/ClePlate.interface'
import cs from './hitpicking_microplate.scss'

export interface HitpickingMicroplateProps {
  className?: string
  plateNumber: number
  plateAndWellToValues: Record<string, HitpickingValues>
  selectedRowIds: Record<string, boolean>
  setSelectedRowIds: (rowIds: Record<string, boolean>) => void
  cellLineName: string
  useLocalAssets: boolean
}

const getCellColor = (value: HitpickingValues) => {
  if (
    value.koScore >= KO_SCORE_HETEROZYGOUS_BOUNDS[0] &&
    value.koScore <= KO_SCORE_HETEROZYGOUS_BOUNDS[1]
  ) {
    if (
      value.doublingRate >= DOUBLING_RATE_BEST_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_BEST_BOUNDS[1]
    ) {
      return '#3994C1'
    }
    if (
      value.doublingRate >= DOUBLING_RATE_GOOD_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_GOOD_BOUNDS[1]
    ) {
      return '#A7D8F0'
    }
  }

  if (
    value.koScore >= KO_SCORE_HOMOZYGOUS_BOUNDS[0] &&
    value.koScore <= KO_SCORE_HOMOZYGOUS_BOUNDS[1]
  ) {
    if (
      value.doublingRate >= DOUBLING_RATE_BEST_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_BEST_BOUNDS[1]
    ) {
      return '#8662C7'
    }
    if (
      value.doublingRate >= DOUBLING_RATE_GOOD_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_GOOD_BOUNDS[1]
    ) {
      return '#CFBCF2'
    }
  }
  return '#EAEAEA'
}

const getPlaceholderHitpickingPlateForPopover = (
  plateNumber: number,
  parentCellLine: string,
  desiredEdit: string,
) => ({
  id: `{0-hitpicking-${plateNumber}`,
  plateFormat: 'wells_96' as supportedPlateFormats,
  hasAlert: false,
  cellLine: {
    parentCellLine,
    desiredEdit,
    cellLineIndex: 0,
    name: `${parentCellLine}-${desiredEdit}`,
  },
  phase: 'hitpicking' as ClePhase,
  plateNumber,
  wells: {},
  owner: 'Dr. Penelope Chang',
})

const HitpickingMicroplate = ({
  className,
  plateNumber,
  plateAndWellToValues,
  selectedRowIds,
  setSelectedRowIds,
  cellLineName,
  useLocalAssets,
}: HitpickingMicroplateProps) => {
  const handleClickCell = (row: number, col: number) => {
    const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
    setSelectedRowIds({
      ...selectedRowIds,
      [plateAndWellId]: !selectedRowIds[plateAndWellId],
    })
  }

  const tokens = cellLineName.split('-')
  const parentCellLine = `${tokens[0]}-${tokens[1]}`
  const desiredEdit = `${tokens[2]}-${tokens[3]}`

  return (
    <TinyMicroplate
      className={cx(className, cs.hitpickingMicroplate)}
      size='extraLarge'
      plateFormat='wells_96'
      highlights={[
        {
          colorFn: (row, col) => {
            const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
            if (selectedRowIds[plateAndWellId]) {
              return '#2cb1bc'
            }
            const value = plateAndWellToValues[plateAndWellId]
            return getCellColor(value)
          },
        },
      ]}
      showCheckIcon={(row, col) => {
        const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
        return selectedRowIds[plateAndWellId]
      }}
      onClickCell={handleClickCell}
      getPopoverContent={(row, col) => {
        const wellName = convertWellCoordsToWellName(row, col)
        const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
        const confluency = plateAndWellToValues[plateAndWellId].confluency
        return (
          <CleCulturePopover
            clePlate={getPlaceholderHitpickingPlateForPopover(
              plateNumber,
              parentCellLine,
              desiredEdit,
            )}
            wellName={wellName}
            dayToSimulate={3}
            actualConfluency={confluency}
            useLocalAssets={useLocalAssets}
          />
        )
      }}
    />
  )
}

export default HitpickingMicroplate
