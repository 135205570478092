import { find, get } from 'lodash/fp'

import CalcConsumableReqsIcon from '~/assets/images/operator_calc_consumable_reqs.png'
import LoadUnloadItemsIcon from '~/assets/images/operator_load_unload_items.png'
import ManuallyMoveItemsIcon from '~/assets/images/operator_manually_move_items.png'
import PreWarmMediaIcon from '~/assets/images/operator_pre_warm_media.png'
import ScheduleDailyMediaExchangeIcon from '~/assets/images/operator_schedule_daily_media_exchange.png'
import SchedulePassageIcon from '~/assets/images/operator_schedule_passage.png'
import ScheduleRoutinesIcon from '~/assets/images/operator_schedule_routines.png'

export const OPERATOR_ACTION_ICONS = {
  PreWarmMediaIcon,
  CalcConsumableReqsIcon,
  ScheduleDailyMediaExchangeIcon,
  ManuallyMoveItemsIcon,
  ScheduleRoutinesIcon,
  LoadUnloadItemsIcon,
  SchedulePassageIcon,
}

export interface ImplementedOperatorAction {
  name: string
  shortDescription: string
  link: string
  icon: string
  key: string
}

interface FillerOperatorAction {
  id: string
  key: string
  filler: boolean
}

export type OperatorAction = ImplementedOperatorAction | FillerOperatorAction

export const OPERATOR_ACTIONS: OperatorAction[] = [
  {
    name: 'Schedule Continuous Culture',
    shortDescription: 'Schedule a culture plate for continuous maintenance',
    link: '/workcell/operator-actions/demo-schedule-continuous-culture',
    icon: 'ScheduleRoutinesIcon',
    key: 'demo_schedule_continuous_culture',
  },
  {
    name: 'Schedule Routines',
    shortDescription: 'Schedule routines onto the workcell',
    link: '/workcell/operator-actions/schedule',
    icon: 'ScheduleRoutinesIcon',
    key: 'generic_schedule',
  },
  {
    name: 'Load & Unload Items',
    shortDescription: 'Load and unload sample plates and consumables.',
    link: '/workcell/operator-actions/load-unload-items',
    icon: 'LoadUnloadItemsIcon',
    key: 'load_unload_items',
  },
  {
    name: 'Manually Move Items',
    shortDescription:
      'Manually move reagents and tipracks on the workcell between ' +
      'transfer stations and static storage.',
    link: '/workcell/operator-actions/manually-move-items',
    icon: 'ManuallyMoveItemsIcon',
    key: 'move_process_items',
  },
  {
    filler: true,
    id: 'test',
    key: '_filler',
  },
]

export const getOperatorAction = (actionName: string): OperatorAction | undefined => {
  return find(['key', actionName], OPERATOR_ACTIONS)
}

export const getIcon = (iconName: string) => {
  return OPERATOR_ACTION_ICONS[iconName]
}

export const getSortedActionNames = (actionNames: string[]) => {
  return actionNames.sort((a: string, b: string) => {
    const aIndex = OPERATOR_ACTIONS.findIndex(action => get('key', action) === a)
    const bIndex = OPERATOR_ACTIONS.findIndex(action => get('key', action) === b)

    return aIndex - bIndex
  })
}

export const getOperatorActionLink = (actionName: string): string | undefined => {
  const operatorAction = getOperatorAction(actionName)
  if (!operatorAction || 'filler' in operatorAction) return undefined
  return operatorAction?.link
}
