import { filter, get, reject } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import processItemsAPI from '~/api/desktop/processItems'
import reloadItemsAPI from '~/api/operatorActions/reloadItems'
import ManuallyMoveItemsIcon from '~/assets/images/operator_manually_move_items.png'
import { parseLocationString } from '~/utils/location'

import { ProcessItem, ProcessItemLike } from '~/common.interface'
import ProcessItemSelect from '../components/processItem/ProcessItemSelect/ProcessItemSelect'
import LoadingActionMessage from './LoadingActionMessage'
import cs from './manually_move_action.scss'
import { ReloadItemOperatorActionConfig } from './reloadItems/ReloadItemOperatorActionConfig.interface'
import ReloadItemsAction from './reloadItems/ReloadItemsAction'
import { ReloadChange } from './reloadItems/ReloadOperation.interface'
import useOperatorActionConfig from './utils/useOperatorActionConfig'

const MOVE_PROCESS_ITEMS = 'move_process_items'

const ManuallyMoveAction = () => {
  const { config, handleConfigUpdate } =
    useOperatorActionConfig<ReloadItemOperatorActionConfig>(MOVE_PROCESS_ITEMS)

  const [processItems, setProcessItems] = useState(null)
  const [selectedProcessItem, setSelectedProcessItem] = useState<ProcessItem | null>(
    null,
  )

  const getFilteredProcessItems = (_processItems, changes) => {
    const hideProcessItem = processItem => {
      const itemAdded =
        filter(
          {
            uuid: processItem.uuid,
            command: 'load_process_item',
          },
          changes,
        ).length > 0 ||
        filter(
          {
            uuid: processItem.uuid,
            command: 'load_transfer_station',
          },
          changes,
        ).length > 0

      if (itemAdded) {
        return true
      }

      return false
    }

    return reject(hideProcessItem, _processItems)
  }

  const fetchProcessItems = async () => {
    const _processItems = await processItemsAPI.getProcessItems({
      types: [
        'tiprack',
        'reagent_plate',
        'culture_plate',
        'experiment_plate',
        'assay_plate',
      ],
      isCheckedIn: true,
    })

    setProcessItems(_processItems)
    setSelectedProcessItem(null)
  }

  const handleSubmit = () => {
    setProcessItems(null)
    fetchProcessItems()
  }

  const handleSelectProcessItem = processItem => {
    setSelectedProcessItem(processItem)
  }

  const getUnloadForSelectedItem = (
    existingChanges: ReloadChange[],
  ): ReloadChange | null => {
    if (selectedProcessItem === null) return null
    const location = get(['state', 'location'], selectedProcessItem)
    // Do not unload if item has no location.
    if (!location) return null

    // Do not unload if item is already unloaded.
    const itemRemoved =
      filter(
        {
          uuid: selectedProcessItem.uuid,
          command: 'unload_process_item',
        },
        existingChanges,
      ).length > 0 ||
      filter(
        {
          uuid: selectedProcessItem.uuid,
          command: 'unload_transfer_station',
        },
        existingChanges,
      ).length > 0

    if (itemRemoved) return null

    if (location.locationType === 'transfer_station') {
      return {
        instrumentName: location.instrumentName,
        type: 'existing_item',
        command: 'unload_transfer_station',
        uuid: selectedProcessItem.uuid,
        transferStationId: location.locationParams.transferStationId,
        manuallyMove: true,
      }
    }
    if (location.locationType === 'storage') {
      const _location = parseLocationString(location.locationParams.locationString)
      return {
        type: 'existing_item',
        instrumentName: location.instrumentName,
        command: 'unload_process_item',
        uuid: selectedProcessItem.uuid,
        shelfIndex: _location[0],
        levelIndex: _location[1],
        manuallyMove: true,
      }
    }
    return null
  }

  useEffect(() => {
    fetchProcessItems()
  }, [])

  const getChangesForSlotClick = (
    itemUuid: string,
    shelfIndex: number,
    levelIndex: number,
    isChanged: boolean,
    instrumentName: string,
    existingChanges: ReloadChange[],
  ) => {
    const changes: ReloadChange[] = []
    if (!itemUuid) {
      if (!selectedProcessItem) return []
      const unload = getUnloadForSelectedItem(existingChanges)
      if (unload) {
        changes.push(unload)
      }
      changes.push({
        instrumentName,
        command: 'load_process_item',
        type: 'existing_item',
        uuid: selectedProcessItem.uuid,
        shelfIndex,
        levelIndex,
        manuallyMove: true,
      })
    } else if (!isChanged) {
      // Do not remove an item that was just added.
      changes.push({
        instrumentName,
        type: 'existing_item',
        command: 'unload_process_item',
        uuid: itemUuid,
        shelfIndex,
        levelIndex,
        manuallyMove: true,
      })
    }

    return changes
  }

  const getChangesForTransferStationClick = (
    item: ProcessItemLike | null,
    transferStationId: string,
    isChanged: boolean,
    instrumentName: string,
    existingChanges: ReloadChange[],
  ) => {
    const changes: ReloadChange[] = []
    if (!item) {
      if (!selectedProcessItem) return []
      const unload = getUnloadForSelectedItem(existingChanges)
      if (unload) {
        changes.push(unload)
      }
      changes.push({
        instrumentName,
        command: 'load_transfer_station',
        uuid: selectedProcessItem.uuid,
        transferStationId,
        type: 'existing_item',
        manuallyMove: true,
      })
    } else if (!isChanged) {
      // Do not remove an item that was just added.
      changes.push({
        instrumentName,
        command: 'unload_transfer_station',
        uuid: item.uuid,
        transferStationId,
        type: 'existing_item',
        manuallyMove: true,
      })
    }

    return changes
  }

  const renderForm = (executingTask: boolean, changes: ReloadChange[]) => {
    return (
      <ProcessItemSelect
        processItems={getFilteredProcessItems(processItems, changes)}
        selectedProcessItem={selectedProcessItem || undefined}
        onProcessItemSelect={handleSelectProcessItem}
        className={cs.select}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
      />
    )
  }

  const handleResetFormValues = () => {
    setSelectedProcessItem(null)
  }

  return config ? (
    <ReloadItemsAction
      operatorActionName={MOVE_PROCESS_ITEMS}
      actionIcon={ManuallyMoveItemsIcon}
      actionDefaultName='Manually Move Items'
      actionDescription={` Manually move process items between locations without running commands on any
      instruments.`}
      infoText='Click on a location to move process items.'
      renderForm={renderForm}
      submitAPI={reloadItemsAPI.submit}
      getChangesForSlotClick={getChangesForSlotClick}
      getChangesForTransferStationClick={getChangesForTransferStationClick}
      onResetFormValues={handleResetFormValues}
      onSubmit={handleSubmit}
      config={config}
      handleConfigUpdate={handleConfigUpdate}
    />
  ) : (
    <LoadingActionMessage />
  )
}

ManuallyMoveAction.propTypes = {
  className: PropTypes.string,
}

export default ManuallyMoveAction
