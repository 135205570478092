import { flow, keys, map, merge, orderBy, toPairs } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'
import cs from './transfer_station_viz.scss'

import commonDriverAPI from '~/api/desktop/commonDriver'
import processItemsAPIV2 from '~/api/desktop/processItemsV2'
import EditProcessItemDialog from '~/pages/Workcell/components/processItem/EditProcessItemDialog'
import ProcessItemVizSlot, {
  ProcessItemVizSlotColorType,
  ProcessItemVizSlotSize,
} from '~/pages/Workcell/components/processItem/ProcessItemVizSlot'
import { getChangeName } from '~/pages/Workcell/utils/processItemChange'
import PropTypes from '~/propTypes'
import { underscoreize, updateValuesByProperty } from '~/utils/object'

import {
  Instrument,
  NewProcessItemPlaceholder,
  ProcessItem,
  ProcessItemLike,
  ProcessItemType,
  StringOnlyProcessItem,
} from '~/common.interface'
import { MonomerErrorBoundary } from '~/components/MonomerErrorBoundary'
import TinyNotification from '~/components/notifications/TinyNotification'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { ReloadChange } from '../../OperatorActions/reloadItems/ReloadOperation.interface'
import { ProcessItemWithTransferStationId } from '../StorageViz/ProcessItemWithTransferStationId.interface'
import Ot2TransferStationVizView from './Ot2TransferStationVizView'
import TecanTransferStationVizView from './TecanTransferStationVizView'
import TransferStationVizView, {
  SelectedTransferStation,
} from './TransferStationVizView'
import { hasClickableTransferStation } from './hasClickableTransferStation'

interface TransferStationVizProps {
  className?: string
  instrument: Instrument
  onTransferStationClick?: (
    item: ProcessItemLike | null,
    transferStationId: string,
    isChanged: boolean,
  ) => void
  displayAddIconOnHover?: boolean
  displayRemoveIconOnHover?: boolean
  // Changes will be deprecated after we switch to the LoadAndUnloadItems operator action.
  changes?: ReloadChange[]
  reloadKey?: string
  clickableStations?: string[]
  orderNames?: 'asc' | 'desc'
  hideName?: boolean
  displaySize?: ProcessItemVizSlotSize
  selectedLocations?: SelectedTransferStation[]
  clickableOnItemOnly?: boolean
  clickableOnNoItemOnly?: boolean
  clickableItemType?: ProcessItemType
  disabled?: boolean
}

const TransferStationViz = ({
  className,
  instrument,
  onTransferStationClick,
  displayAddIconOnHover,
  displayRemoveIconOnHover,
  changes,
  reloadKey,
  clickableStations,
  orderNames,
  hideName,
  displaySize,
  selectedLocations,
  clickableOnItemOnly,
  clickableOnNoItemOnly,
  disabled,
  clickableItemType,
}: TransferStationVizProps) => {
  const isMounted = useIsMounted()
  const [processItemToEdit, setProcessItemToEdit] = useState<ProcessItem | null>(null)
  const [transferStations, setTransferStations] = useState<Record<
    string,
    ProcessItem | StringOnlyProcessItem | NewProcessItemPlaceholder | null
  > | null>(null)

  const fetchTransferStations = () => {
    if (!instrument) return
    commonDriverAPI
      .getTransferStations(instrument.instrumentName)
      .then(_transferStations => {
        if (!isMounted()) return
        setTransferStations(_transferStations)
      })
  }

  useEffect(() => {
    fetchTransferStations()
  }, [instrument, reloadKey])

  const processedItems = useMemo(() => {
    let newTransferStations: Record<
      string,
      ProcessItem | StringOnlyProcessItem | NewProcessItemPlaceholder | null
    > | null = transferStations

    if (changes) {
      changes.forEach(change => {
        // If an instrument is specified, and it is not this instrument, ignore it.
        if (
          (change.instrumentName &&
            change.instrumentName !== instrument.instrumentName) ||
          !change.transferStationId
        )
          return

        if (!keys(newTransferStations).includes(change.transferStationId)) return
        if (change.command === 'load_transfer_station') {
          const name = getChangeName(change)
          newTransferStations = {
            ...newTransferStations,
            [change.transferStationId]: { uuid: name, isNewItemPlaceholder: true },
          }
        } else if (change.command === 'unload_transfer_station') {
          newTransferStations = {
            ...newTransferStations,
            [change.transferStationId]: null,
          }
        }
      })
    }

    return newTransferStations
  }, [changes, transferStations])

  const getDisplaySize = () => {
    if (displaySize) {
      return displaySize
    }
    if (instrument.instrumentType === 'ot_2') {
      return 'ot2DeckSlot'
    }
    if (instrument.instrumentType === 'tecan_evo') {
      return 'tecanDeckSlot'
    }

    return 'large'
  }

  const updateProcessItem = async (uuid: string, newValues: Partial<ProcessItem>) => {
    setTransferStations(
      updateValuesByProperty(
        ['uuid', uuid],
        oldValues => merge(oldValues, newValues),
        transferStations,
      ),
    )

    // We need to manually underscoreize the state before passing it to the API endpoint,
    // because we are still using the v1 process api endpoint to fetch, which
    // automatically camelizes. We need to improve the typing here as well.
    await processItemsAPIV2.updateProcessItem(uuid, {
      state: underscoreize(newValues.state) as Record<string, never>,
    })

    fetchTransferStations()
  }

  const handleProcessItemEdit = (newProcessItem: ProcessItem) => {
    if (processItemToEdit) {
      updateProcessItem(processItemToEdit.uuid, newProcessItem)
    }
    setProcessItemToEdit(null)
  }

  const changedTransferStations = useMemo(() => {
    const locs = {}

    if (changes) {
      changes.forEach(change => {
        if (change.transferStationId) {
          locs[change.transferStationId] = true
        }
      })
    }

    return locs
  }, [changes])

  const selectedTransferStations = useMemo(() => {
    const locs = {}

    if (selectedLocations) {
      selectedLocations.forEach(location => {
        if (location.transferStationId) {
          locs[location.transferStationId] = true
        }
      })
    }

    return locs
  }, [selectedLocations])

  const isTransferStationChanged = (transferStationId: string) => {
    return (
      changedTransferStations[transferStationId] ||
      selectedTransferStations[transferStationId]
    )
  }

  let processItems: ProcessItemWithTransferStationId[] = flow(
    toPairs,
    map(([transferStationId, processItem]) => ({
      processItem,
      transferStationId,
    })),
  )(processedItems || {})

  if (orderNames) {
    processItems = orderBy('transferStationId', orderNames, processItems)
  }

  const renderTransferStation = (
    transferStation: ProcessItemWithTransferStationId | undefined,
  ) => {
    if (!transferStation) {
      return <ProcessItemVizSlot colorType='invalid' size={getDisplaySize()} />
    }

    const { processItem } = transferStation

    let isClickableStation = true

    if (clickableStations) {
      isClickableStation = clickableStations.includes(transferStation.transferStationId)
    }

    const shouldDisplayAddIcon =
      !processItem && displayAddIconOnHover && isClickableStation
    const shouldDisplayRemoveIcon =
      !!processItem && displayRemoveIconOnHover && isClickableStation

    const shouldHighlight = isTransferStationChanged(transferStation.transferStationId)
    const isDisabled =
      clickableStations &&
      !clickableStations.includes(transferStation.transferStationId)

    const isUnselectable =
      (processItem && clickableOnNoItemOnly) ||
      (!processItem && clickableOnItemOnly) ||
      (processItem &&
        clickableItemType &&
        clickableItemType !== (processItem as ProcessItem).type)

    let colorType: ProcessItemVizSlotColorType | undefined = undefined

    if (shouldHighlight) {
      colorType = 'highlight' as const
    } else if (isUnselectable) {
      colorType = 'unselectable' as const
    }

    const processItemLocation = {
      locationType: 'transfer_station' as const,
      locationParams: {
        transferStationId: transferStation.transferStationId,
      },
      instrumentName: instrument.instrumentName,
    }

    return (
      <ProcessItemVizSlot
        key={transferStation.transferStationId}
        onClick={
          onTransferStationClick && !isDisabled && !isUnselectable
            ? _processItem =>
                onTransferStationClick(
                  _processItem || null,
                  transferStation.transferStationId,
                  isTransferStationChanged(transferStation.transferStationId),
                )
            : undefined
        }
        processItem={processItem || undefined}
        processItemLocation={processItemLocation}
        onPopoverEditClick={setProcessItemToEdit}
        shouldDisplayRemoveIcon={shouldDisplayRemoveIcon}
        shouldDisplayAddIcon={shouldDisplayAddIcon}
        colorType={colorType}
        size={getDisplaySize()}
        disabled={disabled || isDisabled}
      />
    )
  }

  const renderView = () => {
    if (instrument.instrumentType === 'ot_2') {
      return (
        <Ot2TransferStationVizView
          className={className}
          transferStations={processItems}
          renderTransferStation={renderTransferStation}
        />
      )
    }
    if (instrument.instrumentType === 'tecan_evo') {
      return (
        <MonomerErrorBoundary>
          <TecanTransferStationVizView
            className={className}
            hideName={hideName}
            instrument={instrument}
            transferStations={processItems}
            renderTransferStation={renderTransferStation}
            reloadKey={reloadKey}
          />
        </MonomerErrorBoundary>
      )
    }
    return (
      <TransferStationVizView
        hideName={hideName}
        transferStations={processItems}
        renderTransferStation={renderTransferStation}
      />
    )
  }

  const renderNoOptionsMessage = () => {
    const hasClickableLocation = hasClickableTransferStation(
      processItems,
      clickableStations,
      clickableOnItemOnly,
      clickableOnNoItemOnly,
    )

    if (!hasClickableLocation) {
      if (clickableOnItemOnly) {
        return (
          <TinyNotification
            message='No valid items found on transfer stations.'
            type='bareInfo'
            className={cs.noOptionsMessage}
          />
        )
      } else if (clickableOnNoItemOnly) {
        return (
          <TinyNotification
            message='No empty transfer stations are available.'
            type='bareInfo'
            className={cs.noOptionsMessage}
          />
        )
      }
    }
    return null
  }

  return (
    <div className={className}>
      {renderNoOptionsMessage()}
      {renderView()}
      <EditProcessItemDialog
        isOpen={processItemToEdit !== null}
        onClose={() => setProcessItemToEdit(null)}
        onEdit={handleProcessItemEdit}
        processItem={processItemToEdit || undefined}
      />
    </div>
  )
}

TransferStationViz.propTypes = {
  className: PropTypes.string,
  instrument: PropTypes.Instrument,
  onTransferStationClick: PropTypes.func,
  displayAddIconOnHover: PropTypes.bool,
  displayRemoveIconOnHover: PropTypes.bool,
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      command: PropTypes.string,
      uuid: PropTypes.string,
      transferStationId: PropTypes.string,
    }),
  ),
  // Change this to have the viz reload its data.
  reloadKey: PropTypes.string,
  clickableStations: PropTypes.arrayOf(PropTypes.string),
  orderNames: PropTypes.oneOf(['asc', 'desc']),
  hideName: PropTypes.bool,
  displaySize: PropTypes.oneOf([
    'large',
    'ot2DeckSlot',
    'micro',
    'mini',
    'normal',
    'tecanDeckSlot',
  ]),
}

export default TransferStationViz
