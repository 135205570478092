import { useQuery } from '@apollo/client'
import cx from 'classnames'
import { Link, useRouteMatch } from 'react-router-dom'

import { gql } from '~/__generated__/gql'
import Breadcrumbs from '~/components/Breadcrumbs'
import { FullPageError } from '~/components/Errors'
import PageNotFound from '~/components/PageNotFound'

import { Well } from './DemoContext'
import cs from './plate.scss'

const GRAPHQL_QUERY = gql(`
  query PlatePage($id: UUID!) {
    culturePlate(id: $id) {
      id
      barcode
    }
  }
`)

export default function Plate({ id }: { id: string }) {
  const { loading, error, data } = useQuery(GRAPHQL_QUERY, {
    variables: { id },
  })
  if (loading) {
    return <LoadingView id={id} />
  }
  if (error) {
    return <FullPageError error={error} />
  }
  const plate = data?.culturePlate
  if (plate == null) {
    return <PageNotFound />
  }

  return (
    <div>
      {/* TODO(SWE-1306): Use a GraphQL fragment in Breadcrumbs. */}
      <Breadcrumbs plateID={id} />

      <div className={cs.main}>
        <div className={cs.title}>{plate.barcode}</div>

        <div className={cx(cs.plate)}>
          {/* {plate.wells.map(well => (
            // TODO(SWE-1309): Try to prevent the browser from showing a ghost
            // URL under the cursor when dragging.
            <MiniWell
              key={well.pos}
              well={well}
              selected={false}
              onToggleSelected={() => {}}
            />
          ))} */}
        </div>
      </div>
    </div>
  )
}

function LoadingView({ id }: { id: string }) {
  // TODO(SWE-1359): Look for plate ID in Apollo cache
  return (
    <div>
      {/* TODO(SWE-1359): Add a breadcrumb loading view */}
      {/* <Breadcrumbs /> */}

      <div className={cs.main}>
        {/* TODO(SWE-1359): Show plate title if barcode in cache */}
        <div className={cs.title}>Loading plate...</div>

        <div className={cx(cs.plate)}>
          {/* TODO(SWE-1359): Show skeleton plate if layout in cache. */}
        </div>
      </div>
    </div>
  )
}

function MiniWell({
  well,
  selected,
  onToggleSelected,
}: { well: Well; selected: boolean; onToggleSelected: () => void }) {
  const match = useRouteMatch()

  const confluence =
    well.timepoints[well.timepoints.length - 1].measurements?.confluence

  const row = well.pos.charCodeAt(0) - 'A'.charCodeAt(0)
  const col = parseInt(well.pos.slice(1))
  const delayMs = 10 * row + 10 * col

  return (
    <Link
      to={`${match.url}/well/${well.pos}`}
      className={cs.well}
      style={{
        // WARNING: When productionizing this, to avoid XSS, ensure URL string is trusted.
        backgroundImage: `url("https://monomer-slas-demo-cell-images.s3.us-west-2.amazonaws.com/organoid_demo_2024_v2/300px/231221_091518_CD318_TnnT__20231221_091517/A2_03_1_1_Bright%20Field_001.jpg")`,
        backgroundSize: 'cover',
      }}
    >
      <div
        className={cx(cs.overlay, { [cs.selected]: selected })}
        // @ts-expect-error Type definition doesn't support CSS variables.
        style={{ '--toggleTransitionDelay': `${delayMs}ms` }}
      >
        <div className={cs.label}>{well.pos}</div>

        <input
          type='checkbox'
          checked={selected}
          onClick={e => {
            // HACK: Stopping propagation is not ideal because it can impair the
            // data collection of diagnostic tools like Sentry. Try refactoring the
            // parent <Link> to use a custom event handler, in which we check
            // e.target.
            e.stopPropagation()

            onToggleSelected()
          }}
          className={cs.checkbox}
        />

        {confluence ? (
          <div className={cx(cs.label, cs.secondary)}>{Math.round(confluence)}%</div>
        ) : null}
      </div>
    </Link>
  )
}
