// This class takes care of managing the xScale, yScale, xAxis and yAxis.
// Also creating the layers (axis layer, data layer, mouse event layer),
// and handling layout of margins around the chart using transforms.

import cx from 'classnames'
import { Axis, AxisDomain, AxisScale, axisBottom, axisLeft, axisTop } from 'd3-axis'
import { select } from 'd3-selection'
import { merge } from 'lodash/fp'
import 'd3-transition'

import cs from './d3_chart.scss'

export type D3ChartDomain<DomainType> = [DomainType, DomainType]

type XAxisPosition = 'top' | 'bottom'

export interface D3ChartLayoutOptions {
  marginTop: number
  marginRight: number
  marginBottom: number
  marginLeft: number
}

export interface D3ChartAxisOptions<XDomainType, YDomainType> {
  xLabelBuffer: number
  yLabelBuffer: number
  yLabelOffset: number
  showXAxis: boolean
  showYAxis: boolean
  // whether the Y-axis is even initialized
  enableYAxis: boolean
  xAxisPosition: XAxisPosition
  xTicks?: number
  yTicks?: number
  yAxisClassName?: string
  xTickFormat?: (d: XDomainType) => string
  yTickFormat?: (d: YDomainType) => string
  xLabel?: string
  yLabel?: string
  yLabelClassName?: string
  yTickLineClassName?: string | ((data: YDomainType) => string)
}

export type D3ChartClassType<ChartClass, XDomainType, YDomainType, ChartOptions> = new (
  container: HTMLDivElement,
  layoutOptions: Partial<D3ChartLayoutOptions>,
  axisOptions: Partial<D3ChartAxisOptions<XDomainType, YDomainType>>,
  chartOptions: Partial<ChartOptions>,
) => ChartClass

const DEFAULT_LAYOUT_OPTIONS = {
  // Margin around the plot area of the chart.
  // Chart axis and labels can overlap with these.
  marginTop: 10,
  marginRight: 30,
  marginBottom: 43,
  marginLeft: 33,
}

const DEFAULT_AXIS_OPTIONS = {
  xLabelBuffer: 35,
  yLabelBuffer: 25,
  yLabelOffset: 0,
  showXAxis: true,
  showYAxis: true,
  enableYAxis: true,
  xAxisPosition: 'bottom' as XAxisPosition,
}

export default abstract class D3Chart<
  DataType,
  XDomainType extends AxisDomain,
  YDomainType extends AxisDomain,
> {
  axisOptions: D3ChartAxisOptions<XDomainType, YDomainType>
  layoutOptions: D3ChartLayoutOptions
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>
  axisInitialized: boolean
  // Initialized in initialize()
  svg!: d3.Selection<SVGSVGElement, unknown, null, undefined>
  g!: d3.Selection<SVGGElement, unknown, null, undefined>
  axisLayer!: d3.Selection<SVGGElement, unknown, null, undefined>
  dataLayer!: d3.Selection<SVGGElement, unknown, null, undefined>
  mouseEventLayer!: d3.Selection<SVGGElement, unknown, null, undefined>
  _data: DataType | undefined
  xAxisEl: d3.Selection<SVGGElement, unknown, null, undefined> | undefined
  yAxisEl: d3.Selection<SVGGElement, unknown, null, undefined> | undefined
  xScale: AxisScale<XDomainType> | undefined
  yScale: AxisScale<YDomainType> | undefined
  xAxis: Axis<XDomainType> | undefined
  yAxis: Axis<YDomainType> | undefined
  xAxisLabel: d3.Selection<SVGTextElement, unknown, null, undefined> | undefined
  yAxisLabel: d3.Selection<SVGTextElement, unknown, null, undefined> | undefined

  constructor(
    container: HTMLDivElement,
    rootClass,
    layoutOptions: Partial<D3ChartLayoutOptions> = {},
    axisOptions: Partial<D3ChartAxisOptions<XDomainType, YDomainType>> = {},
  ) {
    this.layoutOptions = merge(DEFAULT_LAYOUT_OPTIONS, layoutOptions)
    this.axisOptions = merge(DEFAULT_AXIS_OPTIONS, axisOptions)

    this.container = select(container)
    this.initialize(rootClass)

    this.axisInitialized = false
  }

  initialize = rootClass => {
    const layoutOptions = this.layoutOptions
    const node = this.container.node()
    if (!node) return
    const rect = node.getBoundingClientRect()
    this.container.selectAll('*').remove()

    this.svg = this.container
      .append('svg')
      .attr('class', cx(cs.d3Chart, rootClass))
      .attr('width', rect.width)
      .attr('height', rect.height)

    // Set up container.
    this.g = this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${layoutOptions.marginLeft}, ${layoutOptions.marginTop})`,
      )

    this.axisLayer = this.g.append('g').attr('class', cs.axisLayer)
    this.dataLayer = this.g.append('g').attr('class', cs.dataLayer)

    this.mouseEventLayer = this.g.append('g').attr('class', 'mouse-event-layer')

    // This layer goes on top of the daya layer and is used to handle interactions.
    this.mouseEventLayer
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .attr('width', rect.width)
      .attr('height', rect.height)
  }

  // This is the dimensions of the plot area of the chart.
  // Ex: the axis are at the edge of this plot area.
  getChartDimensions = () => {
    const node = this.container.node()
    if (!node)
      return {
        width: 0,
        height: 0,
      }
    const rect = node.getBoundingClientRect()
    const options = this.layoutOptions

    return {
      width: rect.width - options.marginLeft - options.marginRight,
      height: rect.height - options.marginTop - options.marginBottom,
    }
  }

  /*
    Remember to call this function as part of managing React component lifecycle.
    Implementations should clean up any timers, event listeners or other resources.
   */
  abstract teardown: () => void

  /*
    Receives data and returns { xDomain, yDomain }
  */
  // eslint-disable-next-line no-unused-vars
  abstract getDomains: (data: DataType) => {
    xDomain: D3ChartDomain<XDomainType>
    yDomain: D3ChartDomain<YDomainType>
  }

  _updateData = (data: DataType) => {
    this._data = data
    this.updateData(this._data)
  }

  abstract updateData: (data: DataType) => void
  abstract updateChartOptions: (data: unknown) => void

  abstract getXScale: (xDomain: D3ChartDomain<XDomainType>) => AxisScale<XDomainType>

  getXAxis = (xScale: AxisScale<XDomainType>) => {
    let xAxis: Axis<XDomainType>
    const options = this.axisOptions
    if (options.xAxisPosition === 'bottom') {
      xAxis = axisBottom<XDomainType>(xScale)
    } else {
      // Assume top
      xAxis = axisTop<XDomainType>(xScale)
    }

    return xAxis
  }

  abstract getYScale: (yDomain: D3ChartDomain<YDomainType>) => AxisScale<YDomainType>

  getYAxis = (yScale: AxisScale<YDomainType>) => {
    const yAxis = axisLeft(yScale)

    return yAxis
  }

  initializeAxis = (data: DataType) => {
    const dim = this.getChartDimensions()
    const { xDomain, yDomain } = this.getDomains(data)
    const options = this.axisOptions

    // X axis: scale and draw:
    this.axisLayer.selectAll('*').remove()
    this.xScale = this.getXScale(xDomain)

    if (options.showXAxis) {
      this.xAxis = this.getXAxis(this.xScale)
      this.xAxis = this.setXTicks(this.xAxis)

      if (options.xAxisPosition === 'bottom') {
        this.xAxisEl = this.axisLayer
          .append('g')
          .attr('transform', `translate(0, ${dim.height})`)
          .attr('class', cs.axis)

        this.xAxisEl.call(this.xAxis)
      } else {
        this.xAxisEl = this.axisLayer
          .append('g')
          .attr('transform', 'translate(0, 0)')
          .attr('class', cs.axis)

        this.xAxisEl.call(this.xAxis)
      }

      if (options.xLabel) {
        // text label for the x axis
        this.xAxisLabel = this.axisLayer
          .append('text')
          .attr('x', dim.width / 2)
          .attr('y', dim.height + options.xLabelBuffer)
          .style('text-anchor', 'middle')
          .attr('class', cs.axisLabel)
          .text(options.xLabel)
      }
    }

    if (options.enableYAxis) {
      // Y axis: scale and draw:
      this.yScale = this.getYScale(yDomain)

      if (options.showYAxis) {
        this.yAxis = this.getYAxis(this.yScale)
        this.yAxis = this.setYTicks(this.yAxis)

        this.yAxisEl = this.axisLayer
          .append('g')
          .attr('class', cx(cs.axis, options.yAxisClassName))

        this.yAxisEl.call(this.yAxis)

        if (options.yLabel) {
          // text label for the y axis
          this.yAxisLabel = this.axisLayer
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', -options.yLabelBuffer)
            .attr('x', 0 - dim.height / 2 - options.yLabelOffset)
            .style('text-anchor', 'middle')
            .attr('class', cx(cs.axisLabel, options.yLabelClassName))
            .text(options.yLabel)
        }
      }
    }
  }

  setXTicks = (xAxis: Axis<XDomainType>) => {
    const options = this.axisOptions
    let _xAxis = xAxis
    if (options.xTicks) {
      _xAxis = _xAxis.ticks(options.xTicks)
    }
    if (options.xTickFormat) {
      _xAxis = _xAxis.tickFormat(options.xTickFormat)
    }
    return _xAxis
  }

  setYTicks = (yAxis: Axis<YDomainType>) => {
    const options = this.axisOptions
    let _yAxis = yAxis
    if (options.yTicks) {
      _yAxis = _yAxis.ticks(options.yTicks)
    }
    if (options.yTickFormat) {
      _yAxis = _yAxis.tickFormat(options.yTickFormat)
    }
    return _yAxis
  }

  // This function should be called in the child class if axes are required.
  rerenderAxis = (data: DataType, animationDuration = 0) => {
    if (!this.axisInitialized) {
      this.initializeAxis(data)
      this.axisInitialized = true
      return
    }

    const options = this.axisOptions

    const { xDomain, yDomain } = this.getDomains(data)

    // X axis: scale and draw:
    if (this.xScale) {
      // @ts-expect-error: You can pass xDomain into xScale but there's no type def for it.
      this.xScale.domain(xDomain)
    }
    if (options.showXAxis && this.xAxis && this.xAxisEl) {
      this.xAxis = this.setXTicks(this.xAxis)
      if (animationDuration) {
        this.xAxisEl.transition().duration(animationDuration).call(this.xAxis)
      } else {
        this.xAxisEl.call(this.xAxis)
      }
      if (options.xLabel && this.xAxisLabel) {
        this.xAxisLabel.text(options.xLabel)
      }
    }

    if (options.enableYAxis) {
      // Y axis: scale and draw:
      if (this.yScale) {
        // @ts-expect-error: You can pass yDomain into yScale but there's no type def for it.
        this.yScale.domain(yDomain)
      }

      if (options.showYAxis && this.yAxis && this.yAxisEl) {
        this.yAxis = this.setYTicks(this.yAxis)
        this.yAxisEl.call(this.yAxis)
        if (options.yTickLineClassName) {
          this.yAxisEl
            .selectAll<SVGElement, YDomainType>('.tick line')
            .attr('class', options.yTickLineClassName)
        }
      }

      if (options.yLabel && this.yAxisLabel) {
        this.yAxisLabel.text(options.yLabel)
      }
    }
  }
}
