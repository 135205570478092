import { isLiconicInstrument } from '~/pages/Workcell/components/instrument/isLiconicInstrument'
import { displayCount, snakeCaseToTitleCase } from '~/utils/string'
import {
  CulturePlateLoadParamsRequest,
  EmptyAssayPlateLoadParamsRequest,
  EmptyCulturePlateLoadParamsRequest,
  ReagentPlateLoadParamsRequest,
  ReloadOperationRequest,
  StorageParamsRequest,
} from '../../reloadItems/convertChangeToReloadOperation'
import { GetReloadOperationsOrErrorContext } from './GetReloadOperationsOrErrorContext.interface'

const _getItemString = (
  context: GetReloadOperationsOrErrorContext,
  reloadOperations: ReloadOperationRequest[],
): string => {
  // For now, unload operations only include the uuid of the item being unloaded.
  // See ProcessItemUnloadOperationRequest.
  // As a result, we can't display a descriptive string like
  // "1 mTeSR+ Plate" and just display "1 Reagent Plate" for now.
  // TODO(mark): Be more consistent about what is included in process_item_params of ReloadOperationRequest
  // so we don't have to worry about special casing.
  if (context.loadOrUnload === 'unload') {
    return displayCount(
      snakeCaseToTitleCase(context.processItemType),
      reloadOperations.length,
    )
  }

  if (context.processItemType === 'reagent_plate') {
    const mediaType = (
      reloadOperations[0].process_item_params as ReagentPlateLoadParamsRequest
    ).tags[0]

    return displayCount(`${mediaType} Plate`, reloadOperations.length)
  }
  if (context.processItemType === 'tiprack') {
    const tipType = context.processItemParams.selectedLabware?.tip_type_name

    return displayCount(`${tipType} Tiprack`, reloadOperations.length)
  }

  if (reloadOperations.length > 1) {
    return displayCount(
      snakeCaseToTitleCase(context.processItemType),
      reloadOperations.length,
    )
  }
  if (reloadOperations.length == 0) {
    return ''
  }
  if (context.processItemType === 'culture_plate') {
    return (reloadOperations[0].process_item_params as CulturePlateLoadParamsRequest)
      .uuid
  }

  if (context.processItemType === 'empty_culture_plate') {
    return (
      reloadOperations[0].process_item_params as EmptyCulturePlateLoadParamsRequest
    ).uuid
  }
  if (context.processItemType === 'empty_assay_plate') {
    return (reloadOperations[0].process_item_params as EmptyAssayPlateLoadParamsRequest)
      .uuid
  }
  // Experiment plate is still displayed as experiment plate.

  return displayCount(
    snakeCaseToTitleCase(context.processItemType),
    reloadOperations.length,
  )
}

export const _getInstrumentLoadString = (
  context: GetReloadOperationsOrErrorContext,
  reloadOperations: ReloadOperationRequest[],
): string => {
  if (context.useArmAssist) {
    return `onto the Regrip Nest`
  } else if (isLiconicInstrument(context.selectedInstrument?.instrumentType ?? '')) {
    if (context.manuallyMove) {
      if (
        reloadOperations.length === 1 &&
        reloadOperations[0].command === 'load_process_item'
      ) {
        const storageParams = reloadOperations[0].command_params as StorageParamsRequest
        return `into ${context.selectedInstrument?.instrumentName} (Shelf ${storageParams.shelf_index}, Level ${storageParams.level_index}) via the Door`
      }

      return `into ${context.selectedInstrument?.instrumentName} via the Door`
    } else {
      return `onto the ${context.selectedInstrument?.instrumentName} Transfer Station`
    }
  } else {
    return `onto ${context.selectedInstrument?.instrumentName}`
  }
}

export const _getInstrumentUnloadString = (
  context: GetReloadOperationsOrErrorContext,
  reloadOperations: ReloadOperationRequest[],
): string => {
  if (context.useArmAssist) {
    return `onto the Regrip Nest`
  } else if (isLiconicInstrument(context.selectedInstrument?.instrumentType ?? '')) {
    if (context.manuallyMove) {
      if (
        reloadOperations.length === 1 &&
        reloadOperations[0].command === 'load_process_item'
      ) {
        const storageParams = reloadOperations[0].command_params as StorageParamsRequest
        return `into ${context.selectedInstrument?.instrumentName} (Shelf ${storageParams.shelf_index}, Level ${storageParams.level_index}) via the Door`
      }

      return `into ${context.selectedInstrument?.instrumentName} via the Door`
    } else {
      return `onto the ${context.selectedInstrument?.instrumentName} Transfer Station`
    }
  } else {
    return `onto ${context.selectedInstrument?.instrumentName}`
  }
}

export const getReloadChangesSuccessMessage = (
  context: GetReloadOperationsOrErrorContext,
  reloadOperations: ReloadOperationRequest[],
): string => {
  if (context.loadOrUnload === 'load') {
    const itemString = _getItemString(context, reloadOperations)
    const instrumentLoadString = _getInstrumentLoadString(context, reloadOperations)
    return `Please load ${itemString} ${instrumentLoadString}, then click Load.`
  } else {
    const itemString = _getItemString(context, reloadOperations)
    if (context.useArmAssist) {
      return 'Please ensure the Regrip Nest is empty, then click Unload.'
    } else if (isLiconicInstrument(context.selectedInstrument?.instrumentType ?? '')) {
      if (context.manuallyMove) {
        if (
          reloadOperations.length === 1 &&
          reloadOperations[0].command === 'unload_process_item'
        ) {
          const storageParams = reloadOperations[0]
            .command_params as StorageParamsRequest
          return `Please unload ${itemString} from ${context.selectedInstrument?.instrumentName} (Shelf ${storageParams.shelf_index}, Level ${storageParams.level_index}) via the Door, then click Unload.`
        }

        return `Please unload ${itemString} from ${context.selectedInstrument?.instrumentName} via the Door, then click Unload.`
      } else {
        return `Please ensure the ${context.selectedInstrument?.instrumentName} Transfer Station is empty, then click Unload.`
      }
    } else {
      return `Please unload ${itemString} from ${context.selectedInstrument?.instrumentName}, then click Unload.`
    }
  }
}
