import cx from 'classnames'

import { WellCultureStatusGraphQl } from '~/__generated__/graphql'

import cs from './culture_status.scss'

const HUMAN_READABLE_STATUS: { [status in WellCultureStatusGraphQl]: string } = {
  [WellCultureStatusGraphQl.Active]: 'Active',
  [WellCultureStatusGraphQl.Consumed]: 'Consumed',
  [WellCultureStatusGraphQl.Terminated]: 'Terminated',
  [WellCultureStatusGraphQl.CheckedOut]: 'Checked Out',
}

export function CultureStatus({ status }: { status: WellCultureStatusGraphQl }) {
  return (
    <div>
      <span className={cs.statusLabel}>Status: </span>
      <span
        className={cx(
          cs.status,
          status === WellCultureStatusGraphQl.Active && cs.status__active,
          status === WellCultureStatusGraphQl.Consumed && cs.status__consumed,
          status === WellCultureStatusGraphQl.Terminated && cs.status__terminated,
          status === WellCultureStatusGraphQl.CheckedOut && cs.status__checkedout,
        )}
      >
        {HUMAN_READABLE_STATUS[status]}
      </span>
    </div>
  )
}
