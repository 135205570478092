import { DesktopAPI } from '~/api/core'

const operatorActionAPI = new DesktopAPI('/api/operator-actions')

export type EnabledOperatorConfigs = Record<string, Record<string, unknown>>
export interface GetAllEnabledConfigsResponse {
  configs: EnabledOperatorConfigs
}

export default {
  getConfig: operatorActionName =>
    operatorActionAPI.get(`${operatorActionName}/config/`),
  getConfigSchema: operatorActionName =>
    operatorActionAPI.get(`${operatorActionName}/config-schema/`),
  setConfig: (operatorActionName, newConfig, overwrite) =>
    operatorActionAPI.post(`${operatorActionName}/config/`, {
      config: newConfig,
      overwrite,
    }),
  getAllEnabledConfigs: (): Promise<GetAllEnabledConfigsResponse> =>
    operatorActionAPI.get('all-enabled-configs'),
}
