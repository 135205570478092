import { Route, Switch, useRouteMatch } from 'react-router'

import Well from './Well'
import SiteRoutes from './site/SiteRoutes'

export default function WellRoutes({ plateID }: { plateID: string }) {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        // TODO(SWE-1336): Redirect up to plate view.
        render={() => <div />}
      />

      <Route
        exact
        path={`${match.path}/:pos`}
        render={({
          match: {
            params: { pos },
          },
        }) => <Well plateID={plateID} pos={pos} />}
      />

      <Route
        path={`${match.path}/:pos/site`}
        render={({
          match: {
            params: { pos },
          },
        }) => <SiteRoutes plateID={plateID} wellPos={pos} />}
      />
    </Switch>
  )
}
