import { components } from '~/api/desktop/generated-schema'
import { ReloadChange } from './ReloadOperation.interface'

export type ReloadItemsRequest = components['schemas']['ReloadItemsRequest']
export type ReloadOperationRequest = ReloadItemsRequest['operations'][number]
export type ProcessItemLoadParamsRequest =
  components['schemas']['ProcessItemLoadParamsRequest']
export type ProcessItemUnloadParamsRequest =
  components['schemas']['ProcessItemUnloadParamsRequest']
export type TiprackLoadParamsRequest = components['schemas']['TiprackLoadParamsRequest']
export type EmptyCulturePlateLoadParamsRequest =
  components['schemas']['EmptyCulturePlateLoadParamsRequest']
export type WorkflowCreateParams = components['schemas']['WorkflowCreateParams']
export type CulturePlateLoadParamsRequest =
  components['schemas']['CulturePlateLoadParamsRequest']
export type EmptyAssayPlateLoadParamsRequest =
  components['schemas']['EmptyAssayPlateLoadParamsRequest']
export type ExperimentPlateLoadParamsRequest =
  components['schemas']['ExperimentPlateLoadParamsRequest']
export type ReagentPlateLoadParamsRequest =
  components['schemas']['ReagentPlateLoadParamsRequest']
export type LoadOperationRequest =
  | components['schemas']['TiprackLoadOperationRequest']
  | components['schemas']['ReagentPlateLoadOperationRequest']
export type TransferStationParamsRequest =
  components['schemas']['TransferStationParamsRequest']
export type StorageParamsRequest = components['schemas']['StorageParamsRequest']
export type ExperimentPlateExperiment =
  components['schemas']['ExperimentPlateExperiment']

export const getReloadItemsRequestFromChanges = (
  changes: ReloadChange[],
): ReloadItemsRequest => {
  return {
    operations: changes.map(convertChangeToReloadOperation),
  }
}

export const convertChangeToReloadOperation = (
  change: ReloadChange,
): ReloadOperationRequest => {
  let commmandParams, processItemParams
  if (
    change.command === 'load_process_item' ||
    change.command === 'unload_process_item'
  ) {
    commmandParams = {
      level_index: change.levelIndex,
      shelf_index: change.shelfIndex,
    } as StorageParamsRequest
  } else if (
    change.command === 'unload_transfer_station' ||
    change.command === 'load_transfer_station'
  ) {
    commmandParams = {
      transfer_station_id: change.transferStationId,
    } as TransferStationParamsRequest
  } else {
    throw Error(`Command ${change.command} not supported`)
  }
  if (
    change.command === 'unload_transfer_station' ||
    change.command === 'unload_process_item'
  ) {
    processItemParams = {
      uuid: change.uuid,
    } as ProcessItemUnloadParamsRequest
  } else {
    if (change.type === 'reagent_plate') {
      processItemParams = {
        labware_name: change.labwareName,
        tags: change.tags,
      } as ReagentPlateLoadParamsRequest
    } else if (change.type === 'tiprack') {
      processItemParams = {
        labware_name: change.labwareName,
      } as TiprackLoadParamsRequest
    } else if (change.type === 'experiment_plate') {
      processItemParams = {
        labware_name: change.labwareName,
        experiments: change.experiments?.map(experiment => ({
          experiment_type: experiment.experimentType,
          linked_sample_plate: experiment.linkedSamplePlate,
          well_map: experiment.wellMap,
        })),
      } as ExperimentPlateLoadParamsRequest
    } else if (change.type === 'empty_culture_plate') {
      processItemParams = {
        uuid: change.uuid,
        labware_name: change.labwareName,
      } as EmptyCulturePlateLoadParamsRequest
    } else if (change.type === 'existing_item') {
      processItemParams = {
        labware_name: change.labwareName,
        uuid: change.uuid,
      } as ProcessItemLoadParamsRequest
    } else {
      throw Error(`Type ${change.type} not supported`)
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    instrument_name: change.instrumentName,
    command: change.command,
    command_params: commmandParams,
    type: change.type,
    process_item_params: processItemParams,
    use_arm_assist: false,
    manually_move: change.manuallyMove,
  }
}
