/*
  A tiny, grey notification, to be displayed underneath inputs in forms.
*/

import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import CheckmarkIcon from '~/components/icons/CheckmarkIcon'
import WarningIcon from '~/components/icons/WarningIcon'

import cs from './big_notification.scss'

export type BigNotificationType = 'warning' | 'success' | 'error'

interface BigNotificationProps {
  className?: string
  message?: React.ReactNode
  type: BigNotificationType
}

const BigNotification = ({ className, message, type }: BigNotificationProps) => {
  const renderIcon = () => {
    switch (type) {
      case 'warning':
        return <WarningIcon className={cs.icon} />
      case 'error':
        return <WarningIcon className={cs.icon} />
      case 'success':
        return <CheckmarkIcon className={cs.icon} />
      default:
        return null
    }
  }
  return (
    <div className={cx(className, cs.bigNotification, cs[type])}>
      {renderIcon()}
      <span className={cs.message}>{message}</span>
    </div>
  )
}

BigNotification.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  type: PropTypes.oneOf(['warning', 'success', 'error']),
}

BigNotification.defaultProps = {
  type: 'success',
}

export default BigNotification
