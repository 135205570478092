const camelCaseToTitleCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const snakeCaseToLowerCase = (word: string): string => word.split('_').join(' ')

const snakeCaseToTitleCase = (word: string): string =>
  word
    .split('_')
    .map(_word => _word[0].toUpperCase() + _word.slice(1).toLowerCase())
    .join(' ')

const camelCaseToSnakeCase = (word: string): string =>
  word.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const snakeCaseToCamelCase = (word: string): string =>
  word.replace(/_([a-z])/g, letter => letter[1].toUpperCase())

// Displays "culture" or "cultures" based on the count.
const pluralize = (word: string, count: number): string => {
  if (count === 1) return word
  return `${word}s`
}

// Displays "10 cultures"
const displayCount = (word: string, count: number): string => {
  return `${count} ${pluralize(word, count)}`
}

// Splits a string by a delimiter and rounds each number to a specified number of decimal places.
const splitAndRound = (
  locString: string,
  delimiter: string,
  decimalPlaces = 2,
): string => {
  return locString
    .split(delimiter)
    .map(loc => {
      return Number(loc).toFixed(decimalPlaces)
    })
    .join(delimiter)
}

function parseStringAsBool(
  maybeBool: string | undefined,
  defaultValue: boolean = false,
): boolean {
  if (maybeBool === undefined) {
    return defaultValue
  }
  return maybeBool === 'true'
}

export {
  camelCaseToTitleCase,
  snakeCaseToTitleCase,
  pluralize,
  displayCount,
  splitAndRound,
  camelCaseToSnakeCase,
  snakeCaseToCamelCase,
  snakeCaseToLowerCase,
  parseStringAsBool,
}
