import cx from 'classnames'
import { Link } from 'react-router-dom'

import { FragmentType, gql, useFragment } from '~/__generated__'

import { CultureStatus } from './CultureStatus'
import cs from './culture_page_header.scss'

const culturePageHeaderFragment = gql(`
  fragment CulturePageHeaderFragment on WellCultureGraphQL {
    well
    status
    culturePlate {
      barcode
    }
  }
`)

const CulturePageHeader = (props: {
  wellCulture: FragmentType<typeof culturePageHeaderFragment>
  className?: string
}) => {
  const wellCulture = useFragment(culturePageHeaderFragment, props.wellCulture)

  return (
    <div className={cx(props.className, cs.culturePageHeader)}>
      <Link to={'/monitor/live-cultures/'} className={cs.goBackLabel}>
        Back to Live Cultures
      </Link>

      <div className={cs.title}>
        <div className={cs.titleInner}>
          {wellCulture.culturePlate.barcode}, Well {wellCulture.well}
        </div>
      </div>

      <CultureStatus status={wellCulture.status} />
    </div>
  )
}

export default CulturePageHeader
