import cx from 'classnames'

import cs from './live_cultures_header.scss'

export default function LiveCulturesHeader() {
  return (
    <div className={cx(cs.header)}>
      <div className={cs.titleContainer}>
        <span className={cs.title}>Live Cultures</span>
      </div>
      <div className={cs.fill} />
    </div>
  )
}
