import cx from 'classnames'

import { FragmentType, gql, useFragment } from '~/__generated__'

import CultureImageGrid from './CultureImageGrid'
import cs from './culture_images.scss'

const cultureImagesFragment = gql(`
  fragment CultureImagesFragment on WellCultureGraphQL {
    ...CultureImageGridFragment
  }
`)

const CultureImages = (props: {
  wellCulture: FragmentType<typeof cultureImagesFragment>
  className?: string
}) => {
  const wellCulture = useFragment(cultureImagesFragment, props.wellCulture)

  return (
    <CultureImageGrid
      wellCulture={wellCulture}
      className={cx(cs.imageGrid, props.className)}
    />
  )
}

export default CultureImages
