import cx from 'classnames'
import { forEach } from 'lodash/fp'
import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'

import {
  supportedPlateFormats,
  supportedSize,
} from '~/components/TinyMicroplate.interface'
import { convertWellCoordsToWellName, getWellMatrix } from '~/utils/microplate'

import { Popover2, PopperModifierOverrides } from '@blueprintjs/popover2'
import BareCheckmarkIcon from './icons/BareCheckmarkIcon'
import BareWarningIcon from './icons/BareWarningIcon'
import cs from './tiny_microplate.scss'

export interface TinyMicroplateHighlight {
  color?: string
  row?: string
  cell?: string
  col?: string
  fn?: (row: number, col: number) => boolean
  colorFn?: (row: number, col: number) => string
}

interface TinyMicroplateProps {
  className?: string
  plateFormat: supportedPlateFormats
  highlights?: TinyMicroplateHighlight[]
  getCaption?(...args: unknown[]): ReactNode
  showWellNames?: boolean
  onClickCell?(
    rowIndex: number,
    colIndex: number,
    args: { e: React.MouseEvent<HTMLDivElement, MouseEvent> },
  ): void
  onMouseOverCell?(
    rowIndex: number,
    colIndex: number,
    args: { e: React.MouseEvent<HTMLDivElement, MouseEvent> },
  ): void
  onMouseDownCell?(
    rowIndex: number,
    colIndex: number,
    args: { e: React.MouseEvent<HTMLDivElement, MouseEvent> },
  ): void
  onMouseUp?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void
  onMouseLeave?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void
  getPopoverContent?(rowIndex: number, colIndex: number): JSX.Element | undefined
  showWarningIcon?(row: number, col: number): boolean
  showCheckIcon?(row: number, col: number): boolean
  size?: supportedSize
  shouldAnimate?: boolean
  disabled?: boolean
  hidePopoverArrow?: boolean
  popoverModifiers?: PopperModifierOverrides
}

const TinyMicroplate = ({
  className,
  plateFormat,
  highlights,
  getCaption,
  onClickCell,
  onMouseDownCell,
  onMouseUp,
  onMouseOverCell,
  onMouseLeave,
  getPopoverContent,
  showWellNames,
  showWarningIcon,
  showCheckIcon,
  shouldAnimate,
  disabled,
  popoverModifiers,
  size = 'tiny',
  hidePopoverArrow = false,
}: TinyMicroplateProps) => {
  const wellMatrix = getWellMatrix(plateFormat)

  const getCellLabel = (rowIndex, colIndex): ReactNode => {
    if (getCaption) {
      return getCaption(rowIndex, colIndex)
    }
    if (showWellNames) {
      return convertWellCoordsToWellName(rowIndex, colIndex)
    }
    if (showWarningIcon && showWarningIcon(rowIndex, colIndex)) {
      return <BareWarningIcon className={cs.icon} />
    }
    if (showCheckIcon && showCheckIcon(rowIndex, colIndex)) {
      return <BareCheckmarkIcon className={cs.icon} />
    }
  }
  return (
    <div
      className={cx(
        className,
        cs.tinyMicroplate,
        cs[plateFormat],
        cs[size],
        (onClickCell || onMouseDownCell || getPopoverContent) &&
          !disabled &&
          cs.clickable,
      )}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
    >
      <div className={cs.inner}>
        {wellMatrix.map((row, rowIndex) => (
          <div className={cs.row} key={rowIndex}>
            {row.map((col, colIndex) => {
              const cellRow = col[0]
              const cellCol = col[1]
              let color = 'default'
              let colorRgb = ''

              if (disabled) {
                color = 'disabled'
              } else {
                forEach(highlight => {
                  if (highlight.color) {
                    if (highlight.cell === col) {
                      color = highlight.color
                    }
                    if (highlight.row === cellRow && color === 'default') {
                      color = highlight.color
                    }
                    if (highlight.col === cellCol && color === 'default') {
                      color = highlight.color
                    }
                    if (
                      highlight.fn &&
                      highlight.fn(rowIndex, colIndex) &&
                      color === 'default'
                    ) {
                      color = highlight.color
                    }
                  }
                  if (
                    highlight.colorFn &&
                    highlight.colorFn(rowIndex, colIndex) &&
                    color === 'default'
                  ) {
                    colorRgb = highlight.colorFn(rowIndex, colIndex)
                  }
                }, highlights)
              }

              const cell = (
                <div
                  draggable={false}
                  key={colIndex}
                  onClick={e =>
                    onClickCell && !disabled && onClickCell(rowIndex, colIndex, { e })
                  }
                  onMouseOver={e =>
                    onMouseOverCell && onMouseOverCell(rowIndex, colIndex, { e })
                  }
                  onMouseDown={e =>
                    onMouseDownCell && onMouseDownCell(rowIndex, colIndex, { e })
                  }
                >
                  <div
                    className={cx(cs.cell, cs[color], shouldAnimate && cs.animate)}
                    style={colorRgb ? { backgroundColor: colorRgb } : undefined}
                  >
                    {getCellLabel(rowIndex, colIndex)}
                  </div>
                </div>
              )

              if (!getPopoverContent) {
                return (
                  <div key={colIndex} className={cs.cellContainer}>
                    {cell}
                  </div>
                )
              }

              return (
                <Popover2
                  content={getPopoverContent(rowIndex, colIndex)}
                  interactionKind='hover'
                  placement='auto'
                  hoverOpenDelay={200}
                  hoverCloseDelay={100}
                  className={cs.cellContainer}
                  openOnTargetFocus={false}
                  key={colIndex}
                  minimal={hidePopoverArrow}
                  modifiers={popoverModifiers}
                >
                  {cell}
                </Popover2>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

TinyMicroplate.propTypes = {
  className: PropTypes.string,
  plateFormat: PropTypes.oneOf(['wells_6', 'wells_12', 'wells_24', 'wells_96']),
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      row: PropTypes.string,
      cell: PropTypes.string,
      col: PropTypes.string,
      fn: PropTypes.func,
      colorFn: PropTypes.func,
    }),
  ),
  getCaption: PropTypes.func,
  showWellNames: PropTypes.bool,
  showWarningIcon: PropTypes.func,
  onClickCell: PropTypes.func,
  onMouseOverCell: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseDownCell: PropTypes.func,
  onMouseLeave: PropTypes.func,
  getPopoverContent: PropTypes.func,
  size: PropTypes.oneOf(['micro', 'tiny', 'medium', 'large', 'extraLarge']),
  shouldAnimate: PropTypes.bool,
}

TinyMicroplate.defaultProps = {
  size: 'tiny',
}

export default TinyMicroplate
