import { ReactElement } from 'react'

import Link from '~/components/Link'

import cs from './breadcrumbs.scss'

export default function Breadcrumbs({
  plateID,
  wellPos,
  siteIndex,
}: { plateID?: string; wellPos?: string; siteIndex?: string }) {
  // TODO(SWE-1306): Show plate names instead of IDs.

  const crumbs: (ReactElement | string)[] = []
  if (plateID) {
    crumbs.push(
      <Link
        key='plate'
        to={`/plate/${plateID}`}
        variant={wellPos ? 'primary' : undefined}
      >
        Plate {plateID}
      </Link>,
    )
  }
  if (wellPos) {
    crumbs.push(
      <span key='wellsep' className={cs.separator}>
        {' > '}
      </span>,
    )
    crumbs.push(
      <Link
        key='well'
        to={`/plate/${plateID}/well/${wellPos}`}
        variant={siteIndex ? 'primary' : undefined}
      >
        Well {wellPos}
      </Link>,
    )
  }
  if (siteIndex) {
    crumbs.push(
      <span key='sitesep' className={cs.separator}>
        {' > '}
      </span>,
    )
    crumbs.push(<>Site {parseInt(siteIndex) + 1}</>)
  }

  return <nav className={cs.breadcrumbs}>{crumbs}</nav>
}
