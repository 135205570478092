import Toaster from '~/components/Toaster'
import { client, unwrap } from '../desktopAPIv2'
import { components } from './generated-schema'

export type ProcessItemUpdateDescriptor =
  components['schemas']['ProcessItemUpdateDescriptor']

export default {
  updateProcessItem: async (
    uuid: string,
    processItemUpdate: ProcessItemUpdateDescriptor,
  ) => {
    try {
      await unwrap(
        client().POST('/api/v2/process-items/update/{uuid}', {
          params: {
            path: { uuid },
          },
          body: {
            process_item_values: processItemUpdate,
          },
        }),
      )
      Toaster.show({
        message: `Successfully updated ${uuid}`,
        intent: 'success',
      })
    } catch (e) {
      Toaster.show({
        message: `Failed to update ${uuid}`,
        intent: 'danger',
      })
    }
  },
}
