import cx from 'classnames'
import { get, isArray, isNumber, map, max, range, set } from 'lodash/fp'
import { useMemo } from 'react'

import EditProcessItemDialog from '~/pages/Workcell/components/processItem/EditProcessItemDialog'
import ProcessItemVizSlot, {
  ProcessItemVizSlotColorType,
} from '~/pages/Workcell/components/processItem/ProcessItemVizSlot'
import { getChangeName } from '~/pages/Workcell/utils/processItemChange'
import PropTypes from '~/propTypes'
import { chunk } from '~/utils/array'
import { getLocationString } from '~/utils/location'
import { getUuid } from '~/utils/processItems/common'

import {
  ProcessItem,
  ProcessItemLike,
  ProcessItemType,
  StringOnlyProcessItem,
} from '~/common.interface'
import TinyNotification from '~/components/notifications/TinyNotification'
import { ReloadChange } from '../../OperatorActions/reloadItems/ReloadOperation.interface'
import {
  StorageDimensions,
  StoragePartitions,
} from './StorageInstrumentConfig.interface'
import { NO_REGION, getRegionForSlot } from './getRegionForSlot'
import { hasClickableStorageLocation } from './hasClickableStorageLocation'
import cs from './storage_viz_view.scss'

export interface SelectedStorageLocation {
  itemUuid: string | null
  shelfIndex: number
  levelIndex: number
}

export const isSelectedStorageLocationEqual = (
  locationOne: SelectedStorageLocation,
  locationTwo: SelectedStorageLocation,
) => {
  return (
    locationOne.itemUuid === locationTwo.itemUuid &&
    locationOne.shelfIndex === locationTwo.shelfIndex &&
    locationOne.levelIndex === locationTwo.levelIndex
  )
}

interface StorageVizViewProps {
  className?: string
  dimensions: StorageDimensions
  regions?: StoragePartitions
  defaultRegion?: string
  items?: string[][]
  instrumentName?: string
  highlightLocations?: string[]
  onSlotClick?: (
    processItemUuid: string | null,
    shelfIndex: number,
    levelIndex: number,
    isLocationChanged: boolean,
  ) => void
  displayAddIconOnHover?: boolean
  displayRemoveIconOnHover?: boolean
  // Changes will be deprecated after we switch to the LoadAndUnloadItems operator action.
  changes?: ReloadChange[]
  itemObjects?: Record<string, ProcessItem | StringOnlyProcessItem | null>
  clickablePartitions?: string[]
  processItemToEdit?: ProcessItem | null
  setProcessItemToEdit?: (item: ProcessItem | null) => void
  handleProcessItemEdit?: (item: ProcessItem) => void
  size?: 'large' | 'micro' | 'mini' | 'normal'
  hideShelfLabels?: boolean
  hideLevelLabels?: boolean
  selectedLocations?: SelectedStorageLocation[]
  clickableOnItemOnly?: boolean
  clickableOnNoItemOnly?: boolean
  clickableItemType?: ProcessItemType
  disabled?: boolean
}

const StorageVizView = ({
  className,
  dimensions,
  regions,
  defaultRegion,
  items,
  highlightLocations,
  onSlotClick,
  displayAddIconOnHover,
  displayRemoveIconOnHover,
  changes,
  itemObjects,
  clickablePartitions,
  processItemToEdit,
  setProcessItemToEdit,
  handleProcessItemEdit,
  size,
  hideShelfLabels,
  hideLevelLabels,
  instrumentName = 'Unknown instrument',
  selectedLocations,
  clickableOnItemOnly,
  clickableOnNoItemOnly,
  clickableItemType,
  disabled,
}: StorageVizViewProps) => {
  if (!dimensions) {
    return null
  }

  const processedItems = useMemo(() => {
    let newItems = items || []

    if (changes) {
      changes.forEach(change => {
        if (!isNumber(change.shelfIndex) || !isNumber(change.levelIndex)) return
        if (change.command === 'load_process_item') {
          const name = getChangeName(change)
          newItems = set([change.shelfIndex - 1, change.levelIndex - 1], name, newItems)
        } else if (change.command === 'unload_process_item') {
          newItems = set([change.shelfIndex - 1, change.levelIndex - 1], null, newItems)
        }
      })
    }

    return newItems
  }, [changes, items])

  const changedLocations = useMemo(() => {
    const locs = {}

    if (changes) {
      changes.forEach(change => {
        locs[getLocationString(change.shelfIndex, change.levelIndex)] = true
      })
    }

    return locs
  }, [changes])

  const selectedStorageLocations = useMemo(() => {
    const locs = {}

    if (selectedLocations) {
      selectedLocations.forEach(location => {
        locs[getLocationString(location.shelfIndex, location.levelIndex)] = true
      })
    }

    return locs
  }, [selectedLocations])

  const isLocationChanged = (shelfIndex: number, levelIndex: number) => {
    return changedLocations[getLocationString(shelfIndex, levelIndex)]
  }

  const isLocationSelected = (shelfIndex: number, levelIndex: number) => {
    return selectedStorageLocations[getLocationString(shelfIndex, levelIndex)]
  }

  const getItemAtSlot = (shelfIndex: number, levelIndex: number) => {
    return get([shelfIndex, levelIndex], processedItems)
  }

  // We hide shelf labels when there is only a single shelf present
  const shouldDisplayShelfLabel = !hideShelfLabels && dimensions.numShelves > 1

  const renderSlot = (shelfIndex: number, levelIndex: number) => {
    let { numLevels } = dimensions

    if (isArray(numLevels)) {
      numLevels = numLevels[shelfIndex]
    }

    const regionName = getRegionForSlot(regions, shelfIndex, levelIndex)
    const item = getItemAtSlot(shelfIndex, levelIndex)

    let isInClickablePartition = true

    if (clickablePartitions) {
      isInClickablePartition = clickablePartitions.includes(regionName)
    }

    const shouldDisplayAddIcon =
      !item && displayAddIconOnHover && isInClickablePartition
    const shouldDisplayRemoveIcon =
      !!item &&
      displayRemoveIconOnHover &&
      !isLocationChanged(shelfIndex + 1, levelIndex + 1) &&
      isInClickablePartition

    let itemObject: ProcessItemLike | undefined =
      get(item, itemObjects) || (item && { uuid: item }) || undefined

    if (isLocationChanged(shelfIndex + 1, levelIndex + 1)) {
      itemObject = { uuid: item, isNewItemPlaceholder: true }
    }

    let regionNameLabel = ''

    if (regionName && regionName !== NO_REGION) {
      regionNameLabel = `Region: ${regionName}`
    }

    const shouldHighlight =
      isLocationChanged(shelfIndex + 1, levelIndex + 1) ||
      isLocationSelected(shelfIndex + 1, levelIndex + 1) ||
      (highlightLocations &&
        highlightLocations.includes(`s${shelfIndex + 1}:l${levelIndex + 1}`))

    const isInvalid = regionName === NO_REGION && defaultRegion

    const isDisabled =
      (clickablePartitions && !clickablePartitions.includes(regionName)) ||
      levelIndex >= numLevels

    const isUnselectable =
      (itemObject && clickableOnNoItemOnly) ||
      (!itemObject && clickableOnItemOnly) ||
      (itemObject &&
        clickableItemType &&
        (itemObject as ProcessItem).type !== clickableItemType)

    const processItemLocation = {
      locationType: 'storage' as const,
      locationParams: {
        locationString: getLocationString(shelfIndex + 1, levelIndex + 1),
      },
      instrumentName,
    }
    let colorType: ProcessItemVizSlotColorType | undefined = undefined

    if (shouldHighlight) {
      colorType = 'highlight'
    } else if (isInvalid) {
      colorType = 'invalid'
    } else if (isUnselectable) {
      colorType = 'unselectable'
    }

    return (
      <ProcessItemVizSlot
        key={levelIndex}
        className={cs.slot}
        onClick={
          onSlotClick && !isDisabled && !isUnselectable
            ? processItem =>
                onSlotClick(
                  processItem ? getUuid(processItem) : null,
                  shelfIndex + 1,
                  levelIndex + 1,
                  isLocationChanged(shelfIndex + 1, levelIndex + 1),
                )
            : undefined
        }
        processItem={itemObject}
        processItemLocation={processItemLocation}
        colorType={colorType}
        isHidden={levelIndex >= numLevels}
        additionalLabelInfo={regionNameLabel}
        onPopoverEditClick={setProcessItemToEdit}
        shouldDisplayRemoveIcon={shouldDisplayRemoveIcon}
        shouldDisplayAddIcon={shouldDisplayAddIcon}
        size={size}
        disabled={disabled || isDisabled}
      />
    )
  }

  const renderLevelLabel = (levelIndex: number) => {
    return (
      <div key={levelIndex} className={cs.levelLabel}>
        {levelIndex}
      </div>
    )
  }

  const renderLevelGroup = (maxLevels: number) => {
    return (
      <div className={cs.levelGroup}>
        {shouldDisplayShelfLabel && <div className={cs.levelSpacer} />}
        {range(maxLevels, 0).map(levelIndex => renderLevelLabel(levelIndex))}
      </div>
    )
  }

  const renderShelf = (shelfIndex: number, maxLevels: number) => {
    // Level 0 is at the BOTTOM.
    // Also note that locations are 1-indexed.
    return (
      <div className={cs.shelf} key={shelfIndex}>
        {shouldDisplayShelfLabel && (
          <div className={cs.shelfLabel}>{shelfIndex + 1}</div>
        )}
        {range(maxLevels - 1, -1).map(levelIndex => renderSlot(shelfIndex, levelIndex))}
      </div>
    )
  }

  const renderShelfGroup = (shelves: number[], groupIndex: number) => {
    const maxLevels: number = isArray(dimensions.numLevels)
      ? max(map(shelf => dimensions.numLevels[shelf], shelves))
      : dimensions.numLevels

    return (
      <div className={cs.shelfGroup} key={groupIndex}>
        {!hideLevelLabels && renderLevelGroup(maxLevels)}
        {shelves.map(shelfIndex => renderShelf(shelfIndex, maxLevels))}
      </div>
    )
  }

  const renderNoOptionsMessage = () => {
    const hasClickableLocation = hasClickableStorageLocation(
      dimensions,
      regions,
      processedItems,
      clickablePartitions,
      clickableOnItemOnly,
      clickableOnNoItemOnly,
    )

    if (!hasClickableLocation) {
      if (clickableOnItemOnly) {
        return (
          <TinyNotification
            message='No valid items found on storage.'
            type='bareWarning'
            className={cs.noOptionsMessage}
          />
        )
      } else if (clickableOnNoItemOnly) {
        return (
          <TinyNotification
            message='No empty storage locations are available.'
            type='bareWarning'
            className={cs.noOptionsMessage}
          />
        )
      }
    }
    return null
  }

  const shelfChunks = chunk(range(0, dimensions.numShelves), 10)

  return (
    <>
      <div className={cx(cs.storageVizView, className, size && cs[size])}>
        {renderNoOptionsMessage()}
        {shelfChunks.map(renderShelfGroup)}
      </div>
      <EditProcessItemDialog
        isOpen={processItemToEdit !== null}
        onClose={() => setProcessItemToEdit && setProcessItemToEdit(null)}
        onEdit={handleProcessItemEdit}
        processItem={processItemToEdit || undefined}
      />
    </>
  )
}

StorageVizView.propTypes = {
  className: PropTypes.string,
  dimensions: PropTypes.shape({
    numShelves: PropTypes.number,
    numLevels: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
  }),
  regions: PropTypes.objectOf(
    PropTypes.shape({
      locations: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      shelves: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.number),
      ]),
      levels: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.number),
      ]),
    }),
  ),
  defaultRegion: PropTypes.string,
  // An array of item names.
  items: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  // Map from item name to item object.
  itemObjects: PropTypes.objectOf(PropTypes.shape({})),
  // "s1:l1". Should be 1-indexed.
  highlightLocations: PropTypes.arrayOf(PropTypes.string),
  // (item, shelfIndex, levelIndex)
  onSlotClick: PropTypes.func,
  displayAddIconOnHover: PropTypes.bool,
  displayRemoveIconOnHover: PropTypes.bool,
  // Changes will display in highlighted color.
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      command: PropTypes.string,
      type: PropTypes.string,
      uuid: PropTypes.string,
      _new_item: PropTypes.string,
      shelfIndex: PropTypes.number,
      levelIndex: PropTypes.number,
    }),
  ),
  clickablePartitions: PropTypes.arrayOf(PropTypes.string),
  processItemToEdit: PropTypes.ProcessItem,
  setProcessItemToEdit: PropTypes.func,
  handleProcessItemEdit: PropTypes.func,
  size: PropTypes.oneOf(['large', 'micro', 'mini', 'normal']),
  hideShelfLabels: PropTypes.bool,
  hideLevelLabels: PropTypes.bool,
}

export default StorageVizView
