import cx from 'classnames'
import { get, isString } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import operatorActionAPI, { EnabledOperatorConfigs } from '~/api/operatorActions/shared'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { getComparatorString } from '~/utils/array'
import {
  OPERATOR_ACTION_ICONS,
  getOperatorAction,
  getSortedActionNames,
} from '~/utils/operatorAction'

import { WorkcellStatus } from '~/api/desktop/workcell.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import cs from './operator_actions_menu.scss'

const getEnabledOperatorActionsFromStatus = (
  workcellStatus: WorkcellStatus | undefined,
) => {
  return workcellStatus?.config.enabled_operator_actions || []
}

interface OperatorActionsMenuProps {
  className?: string
}

const OperatorActionsMenu = ({ className }: OperatorActionsMenuProps) => {
  const isMounted = useIsMounted()
  const [operatorActionConfigs, setOperatorActionConfigs] =
    useState<EnabledOperatorConfigs | null>(null)

  const workcellStatus = useContext(WorkcellStatusContext)
  const [enabledOperatorActions, setEnabledOperatorActions] = useState<string[]>()

  const fetchOperatorActionConfigs = () => {
    operatorActionAPI.getAllEnabledConfigs().then(allEnabledConfigs => {
      if (isMounted()) {
        setOperatorActionConfigs(allEnabledConfigs.configs)
      }
    })
  }

  useEffect(() => {
    fetchOperatorActionConfigs()
    setEnabledOperatorActions(getEnabledOperatorActionsFromStatus(workcellStatus))
  }, [getComparatorString(getEnabledOperatorActionsFromStatus(workcellStatus))])

  if (operatorActionConfigs === null) return null

  const renderAction = actionName => {
    const action = getOperatorAction(actionName)

    if (!action) {
      return null
    }

    if ('filler' in action) {
      return <div className={cs.action} key={action.id} />
    }

    let iconName = action.icon

    const customIconName = get([action.key, 'displayIcon'], operatorActionConfigs)
    if (isString(customIconName) && customIconName !== '') {
      iconName = get([action.key, 'displayIcon'], operatorActionConfigs) as string
    }

    const actionIcon = OPERATOR_ACTION_ICONS[iconName]

    return (
      <Link to={action.link} className={cs.action} key={action.name}>
        <div className={cs.actionInner}>
          <img className={cs.icon} src={actionIcon} alt={action.name} />
          <div className={cs.text}>
            <div className={cs.actionName}>
              {get([action.key, 'displayName'], operatorActionConfigs) || action.name}
            </div>
            <div className={cs.actionDescription}>
              {get([action.key, 'displayDescription'], operatorActionConfigs) ||
                action.shortDescription}
            </div>
          </div>
        </div>
      </Link>
    )
  }

  if (!enabledOperatorActions || enabledOperatorActions.length === 0) {
    return <div className={cs.bigMessage}>No operator actions configured.</div>
  }

  const actions = [...getSortedActionNames(enabledOperatorActions), '_filler']

  return (
    <div className={cx(cs.actionContainer, className)}>{actions.map(renderAction)}</div>
  )
}

OperatorActionsMenu.propTypes = {
  className: PropTypes.string,
}

export default OperatorActionsMenu
