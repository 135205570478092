import cx from 'classnames'
import LoadingActionMessage from '../LoadingActionMessage'
import useOperatorActionConfig from '../utils/useOperatorActionConfig'
import LoadUnloadItemsContainer from './LoadUnloadItemsContainer'
import LoadUnloadItemsHeader from './LoadUnloadItemsHeader'
import { LoadUnloadItemsProcessItemType } from './LoadUnloadItemsProcessItemType.interface'
import cs from './load_unload_items_action.scss'

export interface LoadUnloadItemsConfig {
  itemTypesWithSelectableWells: LoadUnloadItemsProcessItemType[]
  allowArmAssist: boolean
  enabledItemTypes: LoadUnloadItemsProcessItemType[]
  itemTypeDisplayAliases: Record<string, string>
  itemTypePreferredInstrumentNames: Record<string, string>
  enableExperimentPlateMapSelect: boolean
  defaultExperimentPlateMapName: string
  enableRotateShelfToDoor: boolean
}

interface LoadUnloadItemsActionProps {
  className?: string
}

const LoadUnloadItemsAction = ({ className }: LoadUnloadItemsActionProps) => {
  const { config, handleConfigUpdate } =
    useOperatorActionConfig<LoadUnloadItemsConfig>('load_unload_items')

  return config ? (
    <div className={cx(className, cs.loadUnloadItemsAction)}>
      <LoadUnloadItemsHeader
        className={cs.header}
        handleConfigUpdate={handleConfigUpdate}
      />
      <LoadUnloadItemsContainer config={config} />
    </div>
  ) : (
    <LoadingActionMessage />
  )
}

export default LoadUnloadItemsAction
