/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment CultureImageGridFragment on WellCultureGraphQL {\n    createdAt\n    status\n    observationHistory {\n      montage {\n        id\n        culture {\n          id\n        }\n        ...MontageImagesDialogFragment\n        images(positions: ALL) {\n          imageUrl(size: SIZE_600)\n        }\n      }\n      confluence\n      timestamp\n    }\n  }\n": types.CultureImageGridFragmentFragmentDoc,
    "\n  fragment CultureImagesFragment on WellCultureGraphQL {\n    ...CultureImageGridFragment\n  }\n": types.CultureImagesFragmentFragmentDoc,
    "\n  fragment CultureOverviewFragment on WellCultureGraphQL {\n    id\n    name\n    culturePlate {\n      checkedInAt\n    }\n    parentCulture {\n      id\n      well\n      culturePlate {\n        barcode\n      }\n    }\n    status\n    confluence {\n      value\n    }\n    confluenceHistory {\n      timestamp\n      value\n    }\n    observationHistory {\n      montage {\n        id\n        culture {\n          id\n        }\n      }\n    }\n    ...CultureImageGridFragment\n  }\n": types.CultureOverviewFragmentFragmentDoc,
    "\n  query CulturePage($id: UUID!) {\n    wellCulture(id: $id) {\n      id\n      well\n      name\n      status\n      createdAt\n      culturePlate {\n        id\n        barcode\n        plateDimensions {\n          rows\n          columns\n        }\n      }\n      parentCulture {\n        id\n        name\n      }\n      ...CulturePageHeaderFragment\n      ...CultureOverviewFragment\n      ...CultureImagesFragment\n    }\n  }\n": types.CulturePageDocument,
    "\n  fragment CulturePageHeaderFragment on WellCultureGraphQL {\n    well\n    status\n    culturePlate {\n      barcode\n    }\n  }\n": types.CulturePageHeaderFragmentFragmentDoc,
    "\n  fragment PlateCulturePopoverFragment on WellCultureGraphQL {\n    id\n    well\n    culturePlate {\n      id\n      barcode\n      checkedInAt\n    }\n    montage {\n      id\n      culture {\n        id\n      }\n      centerImage: images(positions: CENTER) {\n        id\n        imageUrl(size: SIZE_600)\n      }\n    }\n    ...MiniCultureGrowthChartFragment\n    ...PlateCulturePopoverConfluenceInfoFragment\n  }\n": types.PlateCulturePopoverFragmentFragmentDoc,
    "\n  fragment PlateCulturePopoverConfluenceInfoFragment on WellCultureGraphQL {\n    id\n    createdAt\n    status\n    confluence {\n      value\n      lastUpdatedAt\n    }\n  }\n": types.PlateCulturePopoverConfluenceInfoFragmentFragmentDoc,
    "\n  query LiveCultures($status: CulturePlateStatusFilterGraphQL) {\n    filteredCulturePlates: culturePlates(status: $status) {\n      id\n      barcode\n      wellCultures {\n        id\n        well\n        name\n        status\n        createdAt\n        montage {\n          id\n          culture {\n            id\n          }\n        }\n        culturePlate {\n          id\n          barcode\n        }\n        parentCulture {\n          id\n          name\n        }\n      }\n      plateDimensions {\n        rows\n        columns\n      }\n      ...LiveCulturesPlateFragment\n    }\n    ...ConfluenceHistogramFragment\n  }\n": types.LiveCulturesDocument,
    "\n  fragment ConfluenceHistogramFragment on Query {\n    filteredCulturePlates: culturePlates(status: $status)  {\n      wellCultures {\n        confluence {\n          value\n        }\n      }\n    }\n  }\n": types.ConfluenceHistogramFragmentFragmentDoc,
    "\n  fragment LiveCulturesPlateFragment on CulturePlateGraphQL {\n    id\n    barcode\n    plateDimensions {\n      rows\n      columns\n    }\n    wellCultures {\n      id\n      name\n      well\n      status\n      confluence {\n        value\n      }\n      ...PlateCulturePopoverFragment\n    }\n  }\n": types.LiveCulturesPlateFragmentFragmentDoc,
    "\n  fragment MiniCultureGrowthChartFragment on WellCultureGraphQL {\n    id\n    observationHistory {\n      confluence\n      timestamp\n    }\n    culturePlate {\n      checkedInAt\n    }\n  }\n": types.MiniCultureGrowthChartFragmentFragmentDoc,
    "\n  fragment MontageImagesDialogFragment on MontageGraphQL {\n    id\n    largeImages: images(positions: ALL) {\n      montageIndex\n      imageUrl(size: SIZE_1500)\n    }\n    dimensions {\n      rows\n      cols\n    }\n    culture {\n      id\n      createdAt\n      status\n      name\n      culturePlate {\n        barcode\n      }\n      well\n    }\n  }\n": types.MontageImagesDialogFragmentFragmentDoc,
    "\n  query MontageImagesDialogQuery($id:UUID!) {\n    wellCulture(id: $id) {\n      montage {\n        ...MontageImagesDialogFragment\n      }\n      confluence {\n        value\n        lastUpdatedAt\n      }\n    }\n  }\n": types.MontageImagesDialogQueryDocument,
    "\n  query PlatePage($id: UUID!) {\n    culturePlate(id: $id) {\n      id\n      barcode\n    }\n  }\n": types.PlatePageDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CultureImageGridFragment on WellCultureGraphQL {\n    createdAt\n    status\n    observationHistory {\n      montage {\n        id\n        culture {\n          id\n        }\n        ...MontageImagesDialogFragment\n        images(positions: ALL) {\n          imageUrl(size: SIZE_600)\n        }\n      }\n      confluence\n      timestamp\n    }\n  }\n"): (typeof documents)["\n  fragment CultureImageGridFragment on WellCultureGraphQL {\n    createdAt\n    status\n    observationHistory {\n      montage {\n        id\n        culture {\n          id\n        }\n        ...MontageImagesDialogFragment\n        images(positions: ALL) {\n          imageUrl(size: SIZE_600)\n        }\n      }\n      confluence\n      timestamp\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CultureImagesFragment on WellCultureGraphQL {\n    ...CultureImageGridFragment\n  }\n"): (typeof documents)["\n  fragment CultureImagesFragment on WellCultureGraphQL {\n    ...CultureImageGridFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CultureOverviewFragment on WellCultureGraphQL {\n    id\n    name\n    culturePlate {\n      checkedInAt\n    }\n    parentCulture {\n      id\n      well\n      culturePlate {\n        barcode\n      }\n    }\n    status\n    confluence {\n      value\n    }\n    confluenceHistory {\n      timestamp\n      value\n    }\n    observationHistory {\n      montage {\n        id\n        culture {\n          id\n        }\n      }\n    }\n    ...CultureImageGridFragment\n  }\n"): (typeof documents)["\n  fragment CultureOverviewFragment on WellCultureGraphQL {\n    id\n    name\n    culturePlate {\n      checkedInAt\n    }\n    parentCulture {\n      id\n      well\n      culturePlate {\n        barcode\n      }\n    }\n    status\n    confluence {\n      value\n    }\n    confluenceHistory {\n      timestamp\n      value\n    }\n    observationHistory {\n      montage {\n        id\n        culture {\n          id\n        }\n      }\n    }\n    ...CultureImageGridFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CulturePage($id: UUID!) {\n    wellCulture(id: $id) {\n      id\n      well\n      name\n      status\n      createdAt\n      culturePlate {\n        id\n        barcode\n        plateDimensions {\n          rows\n          columns\n        }\n      }\n      parentCulture {\n        id\n        name\n      }\n      ...CulturePageHeaderFragment\n      ...CultureOverviewFragment\n      ...CultureImagesFragment\n    }\n  }\n"): (typeof documents)["\n  query CulturePage($id: UUID!) {\n    wellCulture(id: $id) {\n      id\n      well\n      name\n      status\n      createdAt\n      culturePlate {\n        id\n        barcode\n        plateDimensions {\n          rows\n          columns\n        }\n      }\n      parentCulture {\n        id\n        name\n      }\n      ...CulturePageHeaderFragment\n      ...CultureOverviewFragment\n      ...CultureImagesFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CulturePageHeaderFragment on WellCultureGraphQL {\n    well\n    status\n    culturePlate {\n      barcode\n    }\n  }\n"): (typeof documents)["\n  fragment CulturePageHeaderFragment on WellCultureGraphQL {\n    well\n    status\n    culturePlate {\n      barcode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlateCulturePopoverFragment on WellCultureGraphQL {\n    id\n    well\n    culturePlate {\n      id\n      barcode\n      checkedInAt\n    }\n    montage {\n      id\n      culture {\n        id\n      }\n      centerImage: images(positions: CENTER) {\n        id\n        imageUrl(size: SIZE_600)\n      }\n    }\n    ...MiniCultureGrowthChartFragment\n    ...PlateCulturePopoverConfluenceInfoFragment\n  }\n"): (typeof documents)["\n  fragment PlateCulturePopoverFragment on WellCultureGraphQL {\n    id\n    well\n    culturePlate {\n      id\n      barcode\n      checkedInAt\n    }\n    montage {\n      id\n      culture {\n        id\n      }\n      centerImage: images(positions: CENTER) {\n        id\n        imageUrl(size: SIZE_600)\n      }\n    }\n    ...MiniCultureGrowthChartFragment\n    ...PlateCulturePopoverConfluenceInfoFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlateCulturePopoverConfluenceInfoFragment on WellCultureGraphQL {\n    id\n    createdAt\n    status\n    confluence {\n      value\n      lastUpdatedAt\n    }\n  }\n"): (typeof documents)["\n  fragment PlateCulturePopoverConfluenceInfoFragment on WellCultureGraphQL {\n    id\n    createdAt\n    status\n    confluence {\n      value\n      lastUpdatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query LiveCultures($status: CulturePlateStatusFilterGraphQL) {\n    filteredCulturePlates: culturePlates(status: $status) {\n      id\n      barcode\n      wellCultures {\n        id\n        well\n        name\n        status\n        createdAt\n        montage {\n          id\n          culture {\n            id\n          }\n        }\n        culturePlate {\n          id\n          barcode\n        }\n        parentCulture {\n          id\n          name\n        }\n      }\n      plateDimensions {\n        rows\n        columns\n      }\n      ...LiveCulturesPlateFragment\n    }\n    ...ConfluenceHistogramFragment\n  }\n"): (typeof documents)["\n  query LiveCultures($status: CulturePlateStatusFilterGraphQL) {\n    filteredCulturePlates: culturePlates(status: $status) {\n      id\n      barcode\n      wellCultures {\n        id\n        well\n        name\n        status\n        createdAt\n        montage {\n          id\n          culture {\n            id\n          }\n        }\n        culturePlate {\n          id\n          barcode\n        }\n        parentCulture {\n          id\n          name\n        }\n      }\n      plateDimensions {\n        rows\n        columns\n      }\n      ...LiveCulturesPlateFragment\n    }\n    ...ConfluenceHistogramFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ConfluenceHistogramFragment on Query {\n    filteredCulturePlates: culturePlates(status: $status)  {\n      wellCultures {\n        confluence {\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ConfluenceHistogramFragment on Query {\n    filteredCulturePlates: culturePlates(status: $status)  {\n      wellCultures {\n        confluence {\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment LiveCulturesPlateFragment on CulturePlateGraphQL {\n    id\n    barcode\n    plateDimensions {\n      rows\n      columns\n    }\n    wellCultures {\n      id\n      name\n      well\n      status\n      confluence {\n        value\n      }\n      ...PlateCulturePopoverFragment\n    }\n  }\n"): (typeof documents)["\n  fragment LiveCulturesPlateFragment on CulturePlateGraphQL {\n    id\n    barcode\n    plateDimensions {\n      rows\n      columns\n    }\n    wellCultures {\n      id\n      name\n      well\n      status\n      confluence {\n        value\n      }\n      ...PlateCulturePopoverFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MiniCultureGrowthChartFragment on WellCultureGraphQL {\n    id\n    observationHistory {\n      confluence\n      timestamp\n    }\n    culturePlate {\n      checkedInAt\n    }\n  }\n"): (typeof documents)["\n  fragment MiniCultureGrowthChartFragment on WellCultureGraphQL {\n    id\n    observationHistory {\n      confluence\n      timestamp\n    }\n    culturePlate {\n      checkedInAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MontageImagesDialogFragment on MontageGraphQL {\n    id\n    largeImages: images(positions: ALL) {\n      montageIndex\n      imageUrl(size: SIZE_1500)\n    }\n    dimensions {\n      rows\n      cols\n    }\n    culture {\n      id\n      createdAt\n      status\n      name\n      culturePlate {\n        barcode\n      }\n      well\n    }\n  }\n"): (typeof documents)["\n  fragment MontageImagesDialogFragment on MontageGraphQL {\n    id\n    largeImages: images(positions: ALL) {\n      montageIndex\n      imageUrl(size: SIZE_1500)\n    }\n    dimensions {\n      rows\n      cols\n    }\n    culture {\n      id\n      createdAt\n      status\n      name\n      culturePlate {\n        barcode\n      }\n      well\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MontageImagesDialogQuery($id:UUID!) {\n    wellCulture(id: $id) {\n      montage {\n        ...MontageImagesDialogFragment\n      }\n      confluence {\n        value\n        lastUpdatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query MontageImagesDialogQuery($id:UUID!) {\n    wellCulture(id: $id) {\n      montage {\n        ...MontageImagesDialogFragment\n      }\n      confluence {\n        value\n        lastUpdatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PlatePage($id: UUID!) {\n    culturePlate(id: $id) {\n      id\n      barcode\n    }\n  }\n"): (typeof documents)["\n  query PlatePage($id: UUID!) {\n    culturePlate(id: $id) {\n      id\n      barcode\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;