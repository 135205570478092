import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { ScheduleContinuousCultureAction } from './Demo/ScheduleContinuousCultureAction/ScheduleContinuousCultureAction'
import LoadUnloadItemsAction from './LoadUnloadItems/LoadUnloadItemsAction'
import ManuallyMoveAction from './ManuallyMoveAction'
import OperatorActionsMenu from './OperatorActionsMenu'
import { GenericScheduleAction } from './Schedule/GenericScheduleAction'

const OperatorActions = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={match.path} render={() => <OperatorActionsMenu />} />
      <Route
        path={`${match.path}/load-unload-items`}
        render={() => <LoadUnloadItemsAction />}
      />
      <Route
        path={`${match.path}/manually-move-items`}
        render={() => <ManuallyMoveAction />}
      />
      <Route
        path={`${match.path}/demo-schedule-continuous-culture`}
        render={() => <ScheduleContinuousCultureAction />}
      />
      <Route path={`${match.path}/schedule`} render={() => <GenericScheduleAction />} />
    </Switch>
  )
}

OperatorActions.propTypes = {}

export default OperatorActions
