import cx from 'classnames'
import { useState } from 'react'

import LoadUnloadItemsIcon from '~/assets/images/operator_load_unload_items.png'
import AppHeader from '~/components/AppHeader'
import OperatorActionConfigDialog from '../OperatorActionConfigDialog'
import { LoadUnloadItemsConfig } from './LoadUnloadItemsAction'

export interface LoadUnloadItemsHeaderProps {
  className?: string
  handleConfigUpdate: (config: LoadUnloadItemsConfig) => void
}

const LOAD_UNLOAD_ITEMS = 'load_unload_items'

const LoadUnloadItemsHeader = ({
  className,
  handleConfigUpdate,
}: LoadUnloadItemsHeaderProps) => {
  const [operatorActionConfigDialogOpen, setOperatorActionConfigDialogOpen] =
    useState(false)
  return (
    <div className={cx(className)}>
      <AppHeader
        appName='Load & Unload Items'
        iconSrc={LoadUnloadItemsIcon}
        variant='inline'
        menuOptions={[
          {
            label: 'Configure...',
            action: () => setOperatorActionConfigDialogOpen(true),
          },
        ]}
      />
      <OperatorActionConfigDialog
        isOpen={operatorActionConfigDialogOpen}
        onClose={() => setOperatorActionConfigDialogOpen(false)}
        operatorActionName={LOAD_UNLOAD_ITEMS}
        onConfigUpdate={handleConfigUpdate}
      />
    </div>
  )
}

export default LoadUnloadItemsHeader
