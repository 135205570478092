import { useContext, useEffect, useState } from 'react'

import { get } from 'lodash/fp'
import workcellAPI, {
  WorkcellAllowedInstrumentsForItemType,
} from '~/api/desktop/workcell'
import { ApiV2HttpError } from '~/api/desktopAPIv2'
import executeTaskEndpoint from '~/api/executeTaskEndpoint'
import reloadItemsAPI from '~/api/operatorActions/reloadItems'
import { Instrument } from '~/common.interface'
import Toaster from '~/components/Toaster'
import StatusMessage from '~/components/messages/StatusMessage'
import TinyNotification from '~/components/notifications/TinyNotification'
import useReloadKey from '~/utils/hooks/useReloadKey'
import WorkcellStatusContext from '../../WorkcellStatusContext'
import { SelectedStorageLocation } from '../../components/StorageViz/StorageVizView'
import { SelectedTransferStation } from '../../components/TransferStationViz/TransferStationVizView'
import { isLiconicInstrument } from '../../components/instrument/isLiconicInstrument'
import {
  experimentalEnableWorkflows,
  getInstrumentRealTimeState,
  getIsWorkcellBusy,
} from '../../utils/workcellStatus'
import { StorageParamsRequest } from '../reloadItems/convertChangeToReloadOperation'
import FailedUnloadUserWarning from './FailedUnloadUserWarning'
import { LoadUnloadItemsConfig } from './LoadUnloadItemsAction'
import LoadUnloadItemsFooter from './LoadUnloadItemsFooter'
import InhecoScilaCloseDrawerDialog from './LoadUnloadItemsLocationSelection/InhecoScilaCloseDrawerDialog'
import LoadUnloadItemsLocationSelection from './LoadUnloadItemsLocationSelection/LoadUnloadItemsLocationSelection'
import LoadUnloadItemsProcessItemForm from './LoadUnloadItemsProcessItemForm/LoadUnloadItemsProcessItemForm'
import LoadUnloadItemsTerminateWorkflowsForm from './LoadUnloadItemsProcessItemForm/LoadUnloadItemsTerminateWorkflowsForm'
import { LoadUnloadItemsProcessItemParams } from './LoadUnloadItemsProcessItemParams.interface'
import { LoadUnloadItemsProcessItemType } from './LoadUnloadItemsProcessItemType.interface'
import LoadUnloadItemsTypeSelect from './LoadUnloadItemsTypeSelect'
import { getAllowedInstruments } from './getAllowedInstruments'
import { getAllowedInstrumentsForItemType } from './getAllowedInstrumentsforItemType'
import { getAllowedProcessItemTypes } from './getAllowedProcessItemTypes'
import { ReloadOperationsOrError } from './getReloadOperationsOrError/ReloadOperationsOrError.interface'
import { getReloadChangesOrError } from './getReloadOperationsOrError/getReloadChangesOrError'
import cs from './load_unload_items_body.scss'
import { useSelectedProcessItemTypeAndInstrument as useLoadUnloadSelectedProcessItemTypeAndInstrument } from './useLoadUnloadSelectedProcessItemTypeAndInstrument'

export interface LoadUnloadItemsBodyProps {
  className?: string
  config: LoadUnloadItemsConfig
  loadOrUnload: 'load' | 'unload'
  executingTask: boolean
  setExecutingTask: (executingTask: boolean) => void
}

export interface CloseInhecoDrawerParams {
  drawer: number
  instrumentName: string
}

interface ReloadItemSuccessResponse {
  successMessage: string
}

const LoadUnloadItemsBody = ({
  className,
  config,
  loadOrUnload,
  executingTask,
  setExecutingTask,
}: LoadUnloadItemsBodyProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const workflowsEnabled = experimentalEnableWorkflows(workcellStatus)
  const isWorkcellBusy = getIsWorkcellBusy(workcellStatus)

  const [allInstruments, setAllInstruments] = useState<Instrument[] | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [useArmAssist, setUseArmAssist] = useState(false)
  const [manuallyMove, setManuallyMove] = useState(false)
  const [removeFromCurrentWorkflow, setRemoveFromCurrentWorkflow] = useState(false)
  const [processItemParams, setProcessItemParams] = useState<
    Partial<LoadUnloadItemsProcessItemParams>
  >({})
  const [selectedStorageLocations, setSelectedStorageLocations] = useState<
    SelectedStorageLocation[]
  >([])
  const [selectedTransferStations, setSelectedTransferStations] = useState<
    SelectedTransferStation[]
  >([])
  const [reloadOperationsOrError, setReloadOperationsOrError] =
    useState<ReloadOperationsOrError>({
      operations: [],
      success: '',
      error: 'Loading...',
    })
  const [reloadKey, refreshReloadKey] = useReloadKey()
  const [
    workcellAllowedInstrumentsForItemType,
    setWorkcellAllowedInstrumentsForItemType,
  ] = useState<WorkcellAllowedInstrumentsForItemType | undefined>(undefined)
  const [showFailedUnloadUserWarning, setShowFailedUnloadUserWarning] = useState(false)

  const [taskProgressMessage, setTaskProgressMessage] = useState('')

  // TODO(mark): Find a better place to put this.
  const [inhecoCloseDrawerDialogOpen, setInhecoCloseDrawerDialogOpen] = useState(false)
  const [selectedInhecoDrawerToClose, setSelectedInhecoDrawerToClose] =
    useState<null | CloseInhecoDrawerParams>(null)

  const {
    selectedProcessItemType,
    selectedInstrument,
    setSelectedProcessItemType,
    setSelectedInstrument,
  } = useLoadUnloadSelectedProcessItemTypeAndInstrument(
    config,
    allInstruments,
    workcellAllowedInstrumentsForItemType,
  )

  const fetchInstruments = async () => {
    const _instruments = await workcellAPI.getInstruments()
    setAllInstruments(_instruments)
  }

  const reset = () => {
    setProcessItemParams({})
    setSelectedStorageLocations([])
    setSelectedTransferStations([])
  }

  const fetchWorkcellAllowedInstrumentsForItemType = async () => {
    try {
      const response = await workcellAPI.getWorkcellAllowedInstrumentsForItemType()
      setWorkcellAllowedInstrumentsForItemType(
        // TODO: This cast is required because the openAPI generator is unable to properly
        //  handle string literals as a key
        response.workcell_allowed_instruments_for_item_type as unknown as WorkcellAllowedInstrumentsForItemType,
      )
    } catch (e) {
      setError(String((e as ApiV2HttpError).message))
    }
  }

  const handleSetSelectedInstrument = (instrument: Instrument | null) => {
    setSelectedStorageLocations([])
    setSelectedTransferStations([])
    // Only applies to liconic right now, so we should reset it when the instrument changes.
    setManuallyMove(false)
    setSelectedInstrument(instrument)
  }

  const handleSetSelectedProcessItemType = (
    _selectedProcessItemType: LoadUnloadItemsProcessItemType,
  ) => {
    setSelectedProcessItemType(_selectedProcessItemType)
    reset()
  }

  const resetInhecoScilaDriver = () => {
    setSelectedInhecoDrawerToClose(null)
    setInhecoCloseDrawerDialogOpen(false)
  }

  // Show a warning when liconic unloads fail,
  // reminding the user to manually remove the plates from the software.
  const maybeShowFailedUnloadUserWarning = () => {
    if (
      loadOrUnload === 'unload' &&
      selectedInstrument?.instrumentType &&
      isLiconicInstrument(selectedInstrument?.instrumentType) &&
      !manuallyMove
    ) {
      setShowFailedUnloadUserWarning(true)
    }
  }

  const handleSubmit = async () => {
    if (!selectedInstrument) return
    const operations = reloadOperationsOrError.operations
    if (!operations || operations.length === 0) return

    if (selectedInstrument.instrumentType === 'inheco_scila' && !useArmAssist) {
      const params = operations[0].command_params as StorageParamsRequest
      if (params.level_index) {
        setSelectedInhecoDrawerToClose({
          drawer: params.level_index,
          instrumentName: operations[0].instrument_name,
        })
      }
    }
    const _response = await executeTaskEndpoint<ReloadItemSuccessResponse>(
      () =>
        reloadItemsAPI.submit({
          operations,
        }),
      setExecutingTask,
      setTaskProgressMessage,
    )
    if (_response) {
      if (_response.error) {
        Toaster.show({
          message: _response.error,
          intent: 'danger',
        })
        refreshReloadKey()
        maybeShowFailedUnloadUserWarning()
        return
      } else {
        Toaster.show({
          message: _response.result?.successMessage,
          intent: 'success',
        })
      }
    }
    reset()
    refreshReloadKey()
  }

  useEffect(() => {
    if (!executingTask && selectedInhecoDrawerToClose) {
      const realTimeState = getInstrumentRealTimeState(
        workcellStatus,
        selectedInhecoDrawerToClose.instrumentName,
      )

      const selectedDrawerClosed =
        get(['door_state', selectedInhecoDrawerToClose.drawer - 1], realTimeState) ===
        'Closed'

      if (loadOrUnload === 'unload' && !selectedDrawerClosed) {
        setInhecoCloseDrawerDialogOpen(true)
      }
    }
  }, [executingTask])

  useEffect(() => {
    if (!selectedProcessItemType) {
      setReloadOperationsOrError({
        error: 'No process item type selected',
      })
      return
    }
    const reloadChangesOrError: ReloadOperationsOrError = getReloadChangesOrError({
      loadOrUnload,
      manuallyMove,
      useArmAssist,
      processItemParams,
      selectedInstrument,
      selectedStorageLocations,
      selectedTransferStations,
      processItemType: selectedProcessItemType,
      removeFromCurrentWorkflow,
      isWorkcellBusy,
    })

    setReloadOperationsOrError(reloadChangesOrError)
  }, [
    loadOrUnload,
    useArmAssist,
    manuallyMove,
    processItemParams,
    selectedInstrument,
    selectedStorageLocations,
    selectedTransferStations,
    removeFromCurrentWorkflow,
    isWorkcellBusy,
  ])

  useEffect(() => {
    fetchInstruments()
    fetchWorkcellAllowedInstrumentsForItemType()
  }, [])

  useEffect(() => {
    reset()
  }, [loadOrUnload])

  if (!selectedProcessItemType) {
    return (
      <StatusMessage message='No process item types are configured for this action.' />
    )
  }

  return (
    <div className={className}>
      {showFailedUnloadUserWarning && (
        <FailedUnloadUserWarning
          className={cs.failedUnloadUserWarning}
          selectedInstrument={selectedInstrument}
          onClose={() => setShowFailedUnloadUserWarning(false)}
        />
      )}
      {error && (
        <TinyNotification type='bareError' message={error} className={cs.error} />
      )}
      <div className={cs.typeSelectContainer}>
        <LoadUnloadItemsTypeSelect
          selectedType={selectedProcessItemType}
          triggerClassName={cs.typeSelectTrigger}
          popoverClassName={cs.popoverSelectTrigger}
          onTypeSelect={handleSetSelectedProcessItemType}
          disabled={executingTask}
          allowedProcessItemTypes={getAllowedProcessItemTypes(config)}
          itemTypeDisplayAliases={config.itemTypeDisplayAliases}
        />
      </div>
      <div className={cs.main}>
        <div className={cs.inner}>
          {loadOrUnload === 'load' && (
            <LoadUnloadItemsProcessItemForm
              className={cs.form}
              selectedType={selectedProcessItemType}
              processItemParams={processItemParams}
              setProcessItemParams={setProcessItemParams}
              disabled={executingTask}
              config={config}
            />
          )}
          {allInstruments && workcellAllowedInstrumentsForItemType && (
            <LoadUnloadItemsLocationSelection
              config={config}
              loadOrUnload={loadOrUnload}
              allowedInstruments={getAllowedInstruments(
                allInstruments,
                getAllowedInstrumentsForItemType(
                  workcellAllowedInstrumentsForItemType,
                  selectedProcessItemType,
                ),
              )}
              className={cs.locationSelection}
              useArmAssist={useArmAssist}
              setUseArmAssist={setUseArmAssist}
              manuallyMove={manuallyMove}
              setManuallyMove={setManuallyMove}
              selectedInstrument={selectedInstrument}
              setSelectedInstrument={handleSetSelectedInstrument}
              selectedStorageLocations={selectedStorageLocations}
              setSelectedStorageLocations={setSelectedStorageLocations}
              selectedTransferStations={selectedTransferStations}
              setSelectedTransferStations={setSelectedTransferStations}
              reloadKey={reloadKey}
              disabled={executingTask}
              selectedProcessItemType={selectedProcessItemType}
            />
          )}
          {loadOrUnload === 'unload' &&
            selectedProcessItemType === 'culture_plate' &&
            workflowsEnabled && (
              <LoadUnloadItemsTerminateWorkflowsForm
                className={cs.terminateWorkflowsForm}
                removeFromCurrentWorkflow={removeFromCurrentWorkflow}
                setRemoveFromCurrentWorkflow={setRemoveFromCurrentWorkflow}
                disabled={executingTask}
              />
            )}
        </div>
      </div>
      <LoadUnloadItemsFooter
        loadOrUnload={loadOrUnload}
        config={config}
        className={cs.footer}
        reloadOperationsOrError={reloadOperationsOrError}
        taskProgressMessage={taskProgressMessage}
        onSubmit={handleSubmit}
        executingTask={executingTask}
        selectedProcessItemType={selectedProcessItemType}
      />
      <InhecoScilaCloseDrawerDialog
        isOpen={inhecoCloseDrawerDialogOpen}
        selectedDrawer={selectedInhecoDrawerToClose}
        onClose={resetInhecoScilaDriver}
        instrumentName={
          selectedInstrument ? selectedInstrument.instrumentName : 'Unknown'
        }
      />
    </div>
  )
}

export default LoadUnloadItemsBody
