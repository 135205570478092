import { useQuery } from '@apollo/client'
import cx from 'classnames'
import { color, interpolateHcl } from 'd3'
import { scaleThreshold } from 'd3-scale'
import { floor, range, size } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { FragmentType, gql, useFragment } from '~/__generated__'
import {
  CulturePlateStatusFilterGraphQl,
  LiveCulturesQuery,
  WellCultureStatusGraphQl,
} from '~/__generated__/graphql'
import { FullPageError } from '~/components/Errors'
import Histogram from '~/components/Histogram'
import LoadingMessage from '~/components/LoadingMessage'
import TinyMicroplate from '~/components/TinyMicroplate'
import {
  SUPPORTED_PLATE_DIMENSIONS,
  supportedPlateFormats,
} from '~/components/TinyMicroplate.interface'
import { D3Legend } from '~/components/d3/D3Legend'
import { analytics } from '~/core/analytics'
import { useFeatureFlag } from '~/core/featureFlags'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import { displayCount } from '~/utils/string'
import { clearURLParam, getURLParams, pushURLParam, replaceURLParam } from '~/utils/url'

import { OVER_CONFLUENT_THRESHOLD } from '../../events/ViewModels/CultureViewModel'
import { useMetrics } from '../../metrics'
import { PlateCulturePopover } from '../CulturePopover/CulturePopover'
import { MontageImagesDialogStandalone } from '../MontageImagesDialog/MontageImagesDialog'
import {
  DataToDisplay,
  LiveCulturesFilters,
  SelectedLiveCulturesFilter,
  deserializeDataToDisplay,
} from './LiveCulturesFilters'
import LiveCulturesHeader from './LiveCulturesHeader'
import cs from './live_cultures.scss'

const EMPTY_CULTURE_COLOR = '#EAEAEA'
const DEAD_CULTURE_COLOR = '#CCCCCC'
// const CELL_DEATH_COLOR = '#D64545'
const OVER_CONFLUENT_COLOR = '#FF8F4F'
const CONFLUENCY_COLORS = ['#BFF5FB', '#80D0D8', '#2CB1BC', '#F3C968', '#FF8F4F']

const GRAPHQL_QUERY = gql(`
  query LiveCultures($status: CulturePlateStatusFilterGraphQL) {
    filteredCulturePlates: culturePlates(status: $status) {
      id
      barcode
      wellCultures {
        id
        well
        name
        status
        createdAt
        montage {
          id
          culture {
            id
          }
        }
        culturePlate {
          id
          barcode
        }
        parentCulture {
          id
          name
        }
      }
      plateDimensions {
        rows
        columns
      }
      ...LiveCulturesPlateFragment
    }
    ...ConfluenceHistogramFragment
  }
`)

type CulturePlate = LiveCulturesQuery['filteredCulturePlates'][0]
type WellCulture = CulturePlate['wellCultures'][0]

export const LiveCultures = () => {
  const metrics = useMetrics()
  useEffect(() => metrics.liveCultures.open(), [metrics])
  useEffect(() => {
    analytics.page('Monitor', 'Live Cultures')
  }, [])

  const plateNameURLParam = getURLParams().get('plateName')
  const initialPlateName = plateNameURLParam
    ? { kind: 'SPECIFIC_VALUE', value: plateNameURLParam as string }
    : { kind: 'ALL' }
  const [selectedPlateName, setSelectedPlateName] =
    useState<SelectedLiveCulturesFilter>(initialPlateName as SelectedLiveCulturesFilter)

  const cultureStatusURLParam = getURLParams().get('cultureStatus')
  // By default (i.e. if no URL param was specified), we want to only show active cultures
  let initialCultureStatus: CulturePlateStatusFilterGraphQl
  if (cultureStatusURLParam == null) {
    initialCultureStatus = CulturePlateStatusFilterGraphQl.Active
    // We replace the URL param so there isn't an extra history entry the user isn't expecting.
    replaceURLParam('cultureStatus', initialCultureStatus.toString())
  } else {
    initialCultureStatus = cultureStatusURLParam as CulturePlateStatusFilterGraphQl
  }
  const [selectedCultureStatus, setSelectedCultureStatus] =
    useState<CulturePlateStatusFilterGraphQl>(initialCultureStatus)
  const { loading, error, data } = useQuery(GRAPHQL_QUERY, {
    variables: {
      status: selectedCultureStatus,
    },
    pollInterval: 2 * 60 * 1000,
  })

  const SELECTED_DATA_TO_DISPLAY_LS_KEY = 'live_cultures_selected_data_to_display'
  const [selectedDataToDisplay, setSelectedDataToDisplay] = useState(
    deserializeDataToDisplay(
      localStorage.getItem(SELECTED_DATA_TO_DISPLAY_LS_KEY),
      DataToDisplay.AvgConfluence,
    ),
  )
  useEffect(() => {
    localStorage.setItem(SELECTED_DATA_TO_DISPLAY_LS_KEY, selectedDataToDisplay)
  }, [selectedDataToDisplay])

  const [selectedCulture, setSelectedCulture] = useState<WellCulture | null>(null)

  const culturePlatesByID: {
    [id: string]: LiveCulturesQuery['filteredCulturePlates']
  } = useMemo(
    () => Object.fromEntries((data?.filteredCulturePlates ?? []).map(p => [p.id, p])),
    [data?.filteredCulturePlates],
  )

  const culturesByID: { [id: string]: WellCulture } = useMemo(
    () =>
      Object.fromEntries(
        (data?.filteredCulturePlates ?? [])
          .flatMap(p => p.wellCultures)
          .map(c => [c.id, c]),
      ),
    [data?.filteredCulturePlates],
  )

  useEffect(() => {
    analytics.page('Monitor', 'Live Cultures')
  }, [])

  if (error) {
    return <FullPageError error={error} />
  }
  if (loading) {
    return <LoadingMessage className={cs.loadingMessage} label={'Loading plates...'} />
  }
  if (data == null) {
    console.warn('LiveCultures: no data, but not loading either')
    return null
  }

  const visiblePlates = data.filteredCulturePlates.filter(culturePlate =>
    shouldRenderPlate(culturePlate, selectedPlateName),
  )
  const numVisibleCultures = visiblePlates.reduce(
    (total, culturePlate) => total + culturePlate.wellCultures.length,
    0,
  )

  return (
    <div className={cs.liveCultures}>
      <LiveCulturesHeader />
      <LiveCulturesFilters
        selectedPlateName={selectedPlateName}
        onSelectPlateName={value => {
          if (value.kind == 'SPECIFIC_VALUE') {
            pushURLParam('plateName', value.value)
          } else {
            clearURLParam('plateName')
          }
          setSelectedPlateName(value)
        }}
        selectedCultureStatus={selectedCultureStatus}
        onSelectCultureStatus={value => {
          pushURLParam('cultureStatus', value)
          setSelectedCultureStatus(value)
        }}
        selectedDataToDisplay={selectedDataToDisplay}
        onSelectDataToDisplay={value => {
          setSelectedDataToDisplay(value)
        }}
      />
      <div className={cs.liveCulturesInner}>
        <div className={cs.innerHeader}>
          <div className={cs.title}>
            {displayCount('iPSC Plate', visiblePlates.length)},{' '}
            {displayCount('culture', numVisibleCultures)}
          </div>
          <div className={cs.spacer}></div>
          <ConfluenceHistogram rootQuery={data} />
        </div>
        <div className={cs.grid}>
          {visiblePlates.map(culturePlate => (
            <LiveCulturesPlate
              key={culturePlate.id}
              culturePlate={
                culturePlatesByID[culturePlate.id] as FragmentType<
                  typeof liveCulturesPlateFragment
                >
              }
              onCultureOpen={(culture: WellCulture) => {
                setSelectedCulture(culturesByID[culture.id])
              }}
              showAvgConfluence={selectedDataToDisplay === DataToDisplay.AvgConfluence}
            />
          ))}

          {range(0, 8).map(index => (
            <div key={`filler_${index}`} className={cs.gridItem} />
          ))}
        </div>
      </div>
      {selectedCulture?.montage ? (
        <MontageImagesDialogStandalone
          cultureId={selectedCulture.id}
          isOpen={true}
          onClose={() => {
            setSelectedCulture(null)
          }}
        />
      ) : null}
    </div>
  )
}

function shouldRenderPlate(
  culturePlate: CulturePlate,
  plateNameFilter: SelectedLiveCulturesFilter,
): boolean {
  return (
    plateNameFilter.kind === 'ALL' ||
    culturePlate.barcode.toLowerCase().includes(plateNameFilter.value.toLowerCase())
  )
}

const confluenceHistogramFragment = gql(`
  fragment ConfluenceHistogramFragment on Query {
    filteredCulturePlates: culturePlates(status: $status)  {
      wellCultures {
        confluence {
          value
        }
      }
    }
  }
`)

function ConfluenceHistogram(props: {
  rootQuery: FragmentType<typeof confluenceHistogramFragment>
  // culturePlateStatusFilter: CulturePlateStatusFilterGraphQl
}) {
  const rootQuery = useFragment(confluenceHistogramFragment, props.rootQuery)
  const allConfluencyValues = rootQuery.filteredCulturePlates
    .flatMap(culturePlate => culturePlate.wellCultures)
    .filter(wellCulture => wellCulture.confluence != null)
    .map(wellCulture => wellCulture.confluence!.value)

  // Hard-code these y-max values for now to look nice.
  let yMax = 2
  if (size(allConfluencyValues) > 5) {
    yMax = 6
  }

  if (size(allConfluencyValues) > 25) {
    yMax = 20
  }

  if (size(allConfluencyValues) > 150) {
    yMax = 100
  }

  if (size(allConfluencyValues) > 500) {
    yMax = 400
  }

  const marginLeft = 40 // Accommodates Y axis labels

  return (
    <div className={cs.legend}>
      {size(allConfluencyValues) > 0 ? (
        <Histogram
          className={cs.histogram}
          data={allConfluencyValues}
          layoutOptions={{
            marginLeft,
            marginRight: 1,
            marginBottom: 0,
            marginTop: 5, // Accommodates top Y axis label text
          }}
          axisOptions={{
            showXAxis: false,
            yTicks: 2,
            yTickFormat: (n: number) => (n > 0 ? `${n}` : ''),
            yAxisClassName: cs.yAxis,
            yLabel: '# wells',
            yLabelBuffer: marginLeft - 10,
            yLabelClassName: cs.label,
            yTickLineClassName: (n: number) => (n === 0 ? cs.hideTickLine : undefined),
          }}
          options={{
            xDomain: [0, 100],
            yDomain: [0, yMax],
            bins: 50,
            hoverBins: 50,
            getBarColor: (x0: number, x1: number) => {
              const index = floor((x0 + x1) / 2 / 20)
              return CONFLUENCY_COLORS[index]
            },
          }}
        />
      ) : (
        <div className={cs.histogram} />
      )}

      {D3Legend(scaleThreshold([20, 40, 60, 80], CONFLUENCY_COLORS), {
        marginLeft,
      } as any)}
      <div className={cs.label}>Confluence (%)</div>
    </div>
  )
}

export const liveCulturesPlateFragment = gql(`
  fragment LiveCulturesPlateFragment on CulturePlateGraphQL {
    id
    barcode
    plateDimensions {
      rows
      columns
    }
    wellCultures {
      id
      name
      well
      status
      confluence {
        value
      }
      ...PlateCulturePopoverFragment
    }
  }
`)

function LiveCulturesPlate(props: {
  culturePlate: FragmentType<typeof liveCulturesPlateFragment>
  onCultureOpen?(...args: unknown[]): unknown
  showAvgConfluence: boolean
}) {
  const history = useHistory()
  const culturePlate = useFragment(liveCulturesPlateFragment, props.culturePlate)
  const wellCultureByWell = useMemo(
    () => Object.fromEntries(culturePlate.wellCultures.map(wc => [wc.well, wc])),
    [culturePlate],
  )
  const shouldLinkToPlateView = useFeatureFlag('plateWellView2024')

  if (!culturePlate) {
    return <div className={cs.gridItem}></div>
  }

  const { rows, columns } = culturePlate.plateDimensions
  if (
    !SUPPORTED_PLATE_DIMENSIONS.some(
      supported => supported.rows === rows && supported.columns === columns,
    )
  ) {
    console.error(
      `Unsupported plate dimensions for TinyMicroplate: ${rows} x ${columns}`,
    )
    return null
  }
  const plateFormat = `wells_${rows * columns}`

  return (
    <div
      key={culturePlate.id}
      className={cx(cs.gridItem, { [cs.interactive]: shouldLinkToPlateView })}
      onClick={
        shouldLinkToPlateView
          ? () => {
              history.push(`/plate/${culturePlate.id}`)
            }
          : undefined
      }
    >
      <TinyMicroplate
        plateFormat={plateFormat as supportedPlateFormats}
        onClickCell={
          shouldLinkToPlateView
            ? undefined
            : (rowIndex: number, colIndex: number) => {
                const wellName = convertWellCoordsToWellName(rowIndex, colIndex)
                const wellCulture = wellCultureByWell[wellName]
                // TODO: We should update the TinyMicroplate component to allow selective disabling
                //  of the pointer.
                if (wellCulture) {
                  history.push(`/monitor/culture/${wellCulture.id}`)
                }
              }
        }
        highlights={[
          {
            colorFn: (row, col) => {
              const wellName = convertWellCoordsToWellName(row, col)
              const wellCulture = wellCultureByWell[wellName]
              if (!wellCulture) {
                return EMPTY_CULTURE_COLOR
              }
              if (wellCulture.status !== WellCultureStatusGraphQl.Active) {
                return DEAD_CULTURE_COLOR
              }
              const confluenceValue = wellCulture.confluence?.value ?? 0
              if (confluenceValue > OVER_CONFLUENT_THRESHOLD) {
                return OVER_CONFLUENT_COLOR
              }
              return getConfluencyColor(confluenceValue)
            },
          },
        ]}
        getPopoverContent={
          shouldLinkToPlateView
            ? undefined
            : (row, col) => {
                const wellName = convertWellCoordsToWellName(row, col)
                const wellCulture = wellCultureByWell[wellName]
                if (!wellCulture) {
                  return undefined
                }
                return (
                  <PlateCulturePopover
                    wellCulture={wellCultureByWell[wellName]}
                    onImageClick={() => props.onCultureOpen?.(wellCulture)}
                  />
                )
              }
        }
        size='medium'
        shouldAnimate
      />
      <div className={cs.plateName}>{culturePlate.barcode}</div>
      {props.showAvgConfluence ? (
        <AverageConfluence culturePlate={props.culturePlate} />
      ) : null}
    </div>
  )
}

function interpolateColor(colorOne: string, colorTwo: string, value: number) {
  const rgbString = color(interpolateHcl(colorOne, colorTwo)(value))

  if (rgbString) {
    return rgbString.formatHex()
  }

  return colorOne
}

// Stretch certain ranges out more according to IPSC_CULTURE_CONFLUENCY_DATASETS.
export function getConfluencyColor(confluency: number, forText = false) {
  if (confluency < 36 && forText) {
    return CONFLUENCY_COLORS[1]
  }
  if (confluency < 20) {
    return CONFLUENCY_COLORS[0]
  }
  if (confluency >= 20 && confluency < 36) {
    return interpolateColor(
      CONFLUENCY_COLORS[0],
      CONFLUENCY_COLORS[1],
      (confluency - 20) / 16,
    )
  }
  // IPSC_CULTURE_CONFLUENCY_DATASETS rarely has values here, so keep it one color.
  if (confluency >= 36 && confluency < 50) {
    return CONFLUENCY_COLORS[1]
  }
  if (confluency >= 50 && confluency < 58) {
    return interpolateColor(
      CONFLUENCY_COLORS[1],
      CONFLUENCY_COLORS[2],
      (confluency - 50) / 8,
    )
  }
  if (confluency >= 58 && confluency < 70) {
    return CONFLUENCY_COLORS[2]
  }
  if (confluency >= 70 && confluency < 80) {
    return CONFLUENCY_COLORS[3]
  }

  return CONFLUENCY_COLORS[4]
}

function AverageConfluence(props: {
  culturePlate: FragmentType<typeof liveCulturesPlateFragment>
}) {
  const culturePlate = useFragment(liveCulturesPlateFragment, props.culturePlate)

  const activeCultures = culturePlate.wellCultures.filter(
    ({ status }) => status === WellCultureStatusGraphQl.Active,
  )
  if (activeCultures.length === 0) {
    return (
      <div className={cx(cs.avgConfluence, cs.placeholder)}>
        <br />
      </div>
    )
  }

  const culturesToAverage = activeCultures.filter(
    ({ confluence }) => confluence != null,
  )
  if (culturesToAverage.length === 0) {
    return (
      <div
        className={cx(cs.avgConfluence, cs.placeholder)}
        title='No confluence data to show'
      >
        ND
      </div>
    )
  }

  const averageConfluence =
    culturesToAverage
      .map(wellCulture => wellCulture.confluence!.value)
      .reduce((sum, value) => sum + value, 0) / culturesToAverage.length
  return (
    <div
      className={cs.avgConfluence}
      style={{ color: getConfluencyColor(averageConfluence, true) }}
      title={`Average confluence of ${culturesToAverage.length} wells`}
    >
      {Math.round(averageConfluence)}%
    </div>
  )
}
