import { Instrument } from '~/common.interface'
import { isLiconicInstrument } from '~/pages/Workcell/components/instrument/isLiconicInstrument'
import { isStorageInstrument } from '~/utils/instrument'
import { ReloadOperationRequest } from '../../reloadItems/convertChangeToReloadOperation'
import { LoadParamsRequestWrapper } from '../LoadParamsRequestOrError.interface'
import { getLoadParamsRequestOrError } from '../processItemType'
import { GetReloadOperationsOrErrorContext } from './GetReloadOperationsOrErrorContext.interface'
import { ReloadOperationsOrError } from './ReloadOperationsOrError.interface'
import { getLoadStorageOperation } from './getLoadStorageOperation'
import { getLoadTransferStationOperation } from './getLoadTransferStationOperation'
import { getReloadChangesSuccessMessage } from './getReloadChangesSuccessMessage'
import { getUnloadStorageOperation } from './getUnloadStorageOperation'
import { getUnloadTransferStationOperation } from './getUnloadTransferStationOperation'
import { validateReloadOperationWorkflowChanges } from './validateReloadOperationWorkflowChanges'

export const getReloadChangesOrError = (
  context: GetReloadOperationsOrErrorContext,
): ReloadOperationsOrError => {
  const maybeWorkflowChangesValidationError =
    validateReloadOperationWorkflowChanges(context)

  if (maybeWorkflowChangesValidationError) {
    return {
      error: maybeWorkflowChangesValidationError,
    }
  }

  let loadParamsRequestWrapper: LoadParamsRequestWrapper

  // Validate load processItemParams
  if (context.loadOrUnload === 'load') {
    const loadProcessItemParamsOrError = getLoadParamsRequestOrError(
      context.processItemParams,
      context.processItemType,
    )

    if ('error' in loadProcessItemParamsOrError) {
      return {
        error: loadProcessItemParamsOrError.error,
      }
    }
    loadParamsRequestWrapper = loadProcessItemParamsOrError
  }

  if (!context.selectedInstrument) {
    return {
      error: 'Please select an instrument.',
    }
  }

  const isStorageOrTransferStation = isStorageInstrument(
    context.selectedInstrument.instrumentType,
  )
    ? 'storage'
    : 'transfer_station'

  if (isStorageOrTransferStation === 'storage') {
    const useArmAssistLoad = context.useArmAssist && context.loadOrUnload === 'load'
    const useLiconicLoad =
      isLiconicInstrument(context.selectedInstrument.instrumentType) &&
      context.loadOrUnload === 'load' &&
      !context.manuallyMove
    if (
      context.selectedStorageLocations.length === 0 &&
      !useArmAssistLoad &&
      !useLiconicLoad
    ) {
      return context.loadOrUnload === 'load'
        ? {
            error: 'Please select a destination location.',
          }
        : {
            error: 'Please select an item to unload.',
          }
    }

    try {
      let reloadOperations
      // In these situations, a location is not required.
      if (useArmAssistLoad || useLiconicLoad) {
        reloadOperations = [
          getLoadStorageOperation(
            context.selectedInstrument as Instrument,
            null,
            context.processItemType,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (loadParamsRequestWrapper as LoadParamsRequestWrapper).params,
            context.useArmAssist,
            context.manuallyMove,
          ),
        ] as ReloadOperationRequest[]
      } else {
        reloadOperations = context.selectedStorageLocations.map(location => {
          return context.loadOrUnload === 'load'
            ? getLoadStorageOperation(
                context.selectedInstrument as Instrument,
                location,
                context.processItemType,
                loadParamsRequestWrapper.params,
                context.useArmAssist,
                context.manuallyMove,
              )
            : getUnloadStorageOperation(
                context.selectedInstrument as Instrument,
                location,
                context.processItemType,
                context.useArmAssist,
                context.manuallyMove,
                context.removeFromCurrentWorkflow,
              )
        }) as ReloadOperationRequest[]
      }

      return {
        success: getReloadChangesSuccessMessage(context, reloadOperations),
        operations: reloadOperations,
      }
    } catch (e) {
      return {
        error: String(e),
      }
    }
  } else {
    // Handle load and unload transfer station.
    if (context.selectedTransferStations.length === 0) {
      return context.loadOrUnload === 'load'
        ? {
            error: 'Please select a destination location.',
          }
        : {
            error: 'Please select an item to unload.',
          }
    }

    try {
      const reloadOperations = context.selectedTransferStations.map(location => {
        return context.loadOrUnload === 'load'
          ? getLoadTransferStationOperation(
              context.selectedInstrument as Instrument,
              location,
              context.processItemType,
              loadParamsRequestWrapper.params,
              context.useArmAssist,
            )
          : getUnloadTransferStationOperation(
              context.selectedInstrument as Instrument,
              location,
              context.processItemType,
              context.useArmAssist,
              context.removeFromCurrentWorkflow,
            )
      }) as ReloadOperationRequest[]

      return {
        success: getReloadChangesSuccessMessage(context, reloadOperations),
        operations: reloadOperations,
      }
    } catch (e) {
      return {
        error: String(e),
      }
    }
  }
}
