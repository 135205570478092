import { get, map, uniq } from 'lodash/fp'
import { ReloadChange } from '../OperatorActions/reloadItems/ReloadOperation.interface'

export const getChangeName = (change: ReloadChange) => {
  let name
  if (change._new_item) {
    if (change.type === 'reagent_plate') {
      name = get(['tags', 0], change)
    }
    if (change.type === 'tiprack') {
      name = 'TIPRACK'
    }
    if (change.type === 'experiment_plate') {
      const uniqueLinkedSamplePlates = uniq(
        map('linkedSamplePlate', change.experiments),
      )
      name = `EXP PLATE FOR ${uniqueLinkedSamplePlates.join(', ')}`
    }
  } else {
    name = change.uuid
  }

  return name
}
