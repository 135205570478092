import { get, includes } from 'lodash/fp'

import { parseLocationString } from '~/utils/location'

import {
  NewProcessItemPlaceholder,
  ProcessItem,
  ProcessItemLike,
  ProcessItemLocation,
  StringOnlyProcessItem,
} from '~/common.interface'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { getPlateFormatFromDims } from '../microplate'
import {
  getExperimentPlateMetadataDisplay,
  getExperimentPlateNameDisplay,
  getExperimentPlatePercentFilled,
} from './experimentPlate'
import { getPooledConsumablePercentFilled } from './plate'

export const getLocation = (item: ProcessItem): ProcessItemLocation | undefined => {
  return get(['state', 'location'], item)
}

export const getIsCheckedIn = item => {
  return get('isCheckedIn', item)
}

export const processItemMatchesInstrumentNames = (
  item: ProcessItem,
  instrumentNames,
): boolean => {
  const location = getLocation(item)
  return !location || includes(location.instrumentName, instrumentNames)
}

export const getProcessItemLocationDisplayString = location => {
  if (!location) {
    return null
  }
  if (location.locationType === 'transfer_station') {
    return location.locationParams.transferStationId
  }
  if (location.locationType === 'storage') {
    const _location = parseLocationString(location.locationParams.locationString)
    return `Shelf ${_location[0]}, Level ${_location[1]}`
  }
  return null
}

export const TYPE_STATUS_PILL_CONFIG = {
  reagent_plate: { type: 'accent' },
  tiprack: { type: 'accent' },
  culture_plate: { type: 'warning' },
  empty_culture_plate: { type: 'accent' },
  dead_culture_plate: { type: 'error' },
  experiment_plate: { type: 'accent' },
  assay_plate: { type: 'accent' },
  new_item: { type: 'accent' },
  unknown: { type: 'error' },
}

export const getTypeStatusPillLabel = (item: ProcessItemLike) => {
  if (isProcessItem(item)) {
    if (isEmptyCulturePlate(item)) {
      return 'empty_culture_plate'
    }
    if (isDeadCulturePlate(item)) {
      return 'dead_culture_plate'
    }

    return getType(item)
  }

  if (isNewItemPlaceholder(item)) {
    return 'new_item'
  }
  return 'unknown'
}

export const getType = (item: ProcessItem) => {
  return get('type', item)
}

export const getUuid = (item: ProcessItemLike) => {
  return get('uuid', item)
}

export const getMetadata = item => {
  return get('metadata', item)
}

// Primarily used in ReloadItems to determine if a process item has been
// loaded at a particular slot.
export const isValidProcessItem = (processItem: ProcessItemLike): boolean =>
  processItem && getUuid(processItem) !== undefined

export const isProcessItem = (
  processItem: ProcessItemLike,
): processItem is ProcessItem =>
  processItem && (processItem as ProcessItem).type !== undefined

export const isNewItemPlaceholder = (
  processItem: NewProcessItemPlaceholder | StringOnlyProcessItem,
): processItem is NewProcessItemPlaceholder =>
  processItem && (processItem as NewProcessItemPlaceholder).isNewItemPlaceholder

export const isPooledConsumable = (processItem: ProcessItem): boolean =>
  getType(processItem) === 'reagent_plate' || getType(processItem) === 'tiprack'

export const isCulturePlate = (processItem: ProcessItem): boolean =>
  getType(processItem) === 'culture_plate'

export const isExperimentPlate = (processItem: ProcessItem): boolean =>
  getType(processItem) === 'experiment_plate'

//  Note: experiment plates are currently not editable.
export const isEditable = (
  processItem: ProcessItemLike,
): processItem is ProcessItem => {
  const editableItems = new Set(['reagent_plate', 'tiprack', 'culture_plate'])
  return isProcessItem(processItem) && editableItems.has(getType(processItem))
}

export const canCheckOut = (processItem: ProcessItem): boolean => {
  return (
    isValidProcessItem(processItem) &&
    (getLocation(processItem) === null || getLocation(processItem) === undefined) &&
    getIsCheckedIn(processItem)
  )
}

export const canCheckIn = (processItem: ProcessItem): boolean =>
  isValidProcessItem(processItem) && !getIsCheckedIn(processItem)

export const getReagentPlateReagent = (processItem: ProcessItem) =>
  get(['tags', 0], getMetadata(processItem))

export const getTiprackTipType = (processItem: ProcessItem) => {
  return get(['tip_type'], getMetadata(processItem))
}

export const isCulturePlateInStorage = (processItem: ProcessItem): boolean => {
  const location = getLocation(processItem)
  return (
    getType(processItem) === 'culture_plate' &&
    !!location &&
    location.locationType === 'storage'
  )
}

export const getProcessItemPercentFilled = (processItem: ProcessItem) => {
  if (isPooledConsumable(processItem)) {
    return getPooledConsumablePercentFilled(processItem)
  }

  if (isExperimentPlate(processItem)) {
    return getExperimentPlatePercentFilled(processItem)
  }

  return 0
}

export const getShortDisplayName = (processItem: ProcessItemLike): string => {
  if (isProcessItem(processItem)) {
    const type = getType(processItem)

    if (type === 'reagent_plate') {
      return getReagentPlateReagent(processItem)
    }
    if (type === 'tiprack') {
      return getTiprackTipType(processItem)
    }
    if (type === 'experiment_plate') {
      return getExperimentPlateNameDisplay(processItem)
    }
  }

  return getUuid(processItem)
}

// This shows up  in EditProcessItemDialog
export const getMetadataDisplay = (processItem: ProcessItem) => {
  const metadata = get(['metadata'], processItem)
  if (!metadata) return null

  if (processItem.type === 'tiprack') {
    return getTiprackTipType(processItem)
  }
  if (processItem.type === 'reagent_plate') {
    return getReagentPlateReagent(processItem)
  }
  if (processItem.type === 'experiment_plate') {
    return getExperimentPlateMetadataDisplay(processItem)
  }
  return null
}

export const getMetadataColumnDisplay = (processItem: ProcessItem) => {
  const metadata = get(['metadata'], processItem)
  if (!metadata) return null

  if (processItem.type === 'culture_plate') {
    if (processItem.metadata?.parent_plate) {
      return `Passaged from ${processItem.metadata?.parent_plate}`
    }
  }
  return null
}

export const isEmptyCulturePlate = (processItem: ProcessItem): boolean => {
  return (
    processItem.type == 'culture_plate' &&
    !!processItem.state &&
    processItem.state.isEmpty === true
  )
}

export const isDeadCulturePlate = (processItem: ProcessItem): boolean => {
  return (
    processItem.type == 'culture_plate' &&
    !!processItem.state &&
    processItem.state.isDead === true
  )
}

export const getPlateFormat = (processItem: ProcessItem): supportedPlateFormats => {
  const numRows = get(['metadata', 'num_rows'], processItem)
  const numCols = get(['metadata', 'num_cols'], processItem)
  return getPlateFormatFromDims({ numRows, numCols })
}
