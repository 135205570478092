import cx from 'classnames'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import Breadcrumbs from '~/components/Breadcrumbs'
import Link from '~/components/Link'
import PageNotFound from '~/components/PageNotFound'
import Button from '~/components/buttons/Button'
import CloseIcon from '~/components/icons/CloseIcon'
import ExecuteIcon from '~/components/icons/ExecuteIcon'
import { getConfluencyColor } from '~/pages/Monitor/pages/LiveCultures/LiveCultures'

import { Site, Timepoint, useDemoDispatch, useDemoState } from '../DemoContext'
import cs from './well.scss'

interface CollatedTimepoint extends Timepoint {
  sites: Site[]
}

export default function Well({ plateID, pos }: { plateID: string; pos: string }) {
  const { plates, decisionsOpen } = useDemoState()
  const dispatch = useDemoDispatch()

  const plate = plates.find(p => p.id === plateID)
  if (plate == null) {
    return <PageNotFound />
  }
  const well = plate.wells.find(w => w.pos === pos)
  if (well == null) {
    return <PageNotFound />
  }

  const collatedTimepoints = useMemo(() => {
    const timepointsByBucketTimestamp: { [bucket: string]: CollatedTimepoint } =
      Object.fromEntries(
        well.timepoints.map(timepoint => [
          timepoint.bucketTimestamp.toISOString(),
          { ...timepoint, sites: [] },
        ]),
      )
    for (const site of well.sites) {
      for (const siteTimepoint of site.timepoints) {
        const bucket = siteTimepoint.bucketTimestamp.toISOString()
        timepointsByBucketTimestamp[bucket].sites.push({
          ...site,
          timepoints: [siteTimepoint],
        })
      }
    }
    return Object.values(timepointsByBucketTimestamp).sort((a, b) =>
      a.bucketTimestamp < b.bucketTimestamp ? 1 : -1,
    )
  }, [well.timepoints, well.sites])

  return (
    <div>
      <Breadcrumbs plateID={plateID} wellPos={pos} />

      <div className={cs.main}>
        <section>
          <nav className={cs.timeline}>
            {collatedTimepoints.map(timepoint => (
              <TimepointTab
                key={timepoint.bucketTimestamp.getTime()}
                timepoint={timepoint}
              />
            ))}
          </nav>
        </section>

        <section className={cs.detail}>
          <h1>
            {plate.name} ({plate.id}) • Well {well.pos}
          </h1>
          <div className={cs.heroLayout}>
            <BigWell sites={well.sites} />

            <div className={cs.metadata}>
              <p className={cs.label}>Confluence</p>
              <p className={cs.value}>
                {Math.round(collatedTimepoints[0].measurements?.confluence ?? 0)}%
              </p>
              <p className={cs.label}>Cell Type</p>
              <p className={cs.value}>HSC (CD34+)</p>
              <p className={cs.label}>Parent Cell Line</p>
              <p className={cs.value}>
                <Link to='#' variant='primary'>
                  CL035
                </Link>
              </p>
              <p className={cs.label}>Passage Number</p>
              <p className={cs.value}>2</p>
            </div>
          </div>
        </section>

        {decisionsOpen ? (
          <section>
            <DecisionsSidebar />
          </section>
        ) : (
          <div>
            <Button
              variant='minimal'
              IconComponent={ExecuteIcon}
              onClick={() => {
                dispatch?.('OPEN_DECISIONS')
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function TimepointTab({ timepoint }: { timepoint: CollatedTimepoint }) {
  const confluence = timepoint.measurements?.confluence

  return (
    <Link to='#TODO'>
      <div
        className={cs.timepoint}
        style={{
          // @ts-expect-error Type definition doesn't support CSS variables.
          '--accent-color': confluence ? getConfluencyColor(confluence, true) : null,
        }}
      >
        <div className={cs.time}>
          {dayjs(timepoint.actualTimestamp).format('MMM D, YYYY H:mm A')}
        </div>

        <div className={cs.readouts}>
          <div>
            <MiniWell sites={timepoint.sites} />
          </div>
          {confluence ? (
            <span
              className={cs.confluence}
              style={{ color: getConfluencyColor(confluence, true) }}
            >
              {Math.round(confluence)}%
            </span>
          ) : null}
        </div>
      </div>
    </Link>
  )
}

function MiniWell({ sites }: { sites: Site[] }) {
  // TODO(SWE-1335): Should this be modeled somewhere else?
  const gridLayoutClass = { 9: cs.layout9sites }[sites.length]
  if (gridLayoutClass == null) {
    console.error(
      `MiniWell: unsupported acquisition site layout: ${sites.length} sites`,
    )
    return null
  }

  return (
    <div className={cs.miniwell}>
      <div className={cx(cs.grid, gridLayoutClass)}>
        {sites.map(site => (
          // TODO(SWE-1335): Style sites differently depending if an image is available.
          <div className={cs.site}>
            <img
              src='https://monomer-slas-demo-cell-images.s3.us-west-2.amazonaws.com/organoid_demo_2024_v2/300px/231221_091518_CD318_TnnT__20231221_091517/A2_03_1_1_Bright%20Field_001.jpg'
              style={{
                transform: `translate(${-10}px, ${-10}px)`,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

function BigWell({ sites }: { sites: Site[] }) {
  const match = useRouteMatch()

  // TODO(SWE-1335): Should this be modeled somewhere else?
  const gridLayoutClass = { 9: cs.layout9sites }[sites.length]
  if (gridLayoutClass == null) {
    console.error(`BigWell: unsupported acquisition site layout: ${sites.length} sites`)
    return null
  }

  return (
    <div className={cs.bigwell}>
      <div className={cx(cs.grid, gridLayoutClass)}>
        {sites.map(site => (
          // TODO(SWE-1335): Style sites differently depending if an image is available.
          <Link to={`${match.url}/site/${site.index}`}>
            <div className={cx(cs.site, cs.hasImage)}>
              <img
                src='https://monomer-slas-demo-cell-images.s3.us-west-2.amazonaws.com/organoid_demo_2024_v2/1000px/231221_091518_CD318_TnnT__20231221_091517/A1_03_1_1_Bright%20Field_001.jpg'
                style={{
                  transform: `translate(${-70}px, ${-70}px)`,
                }}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

function DecisionsSidebar() {
  const dispatch = useDemoDispatch()

  return (
    <div className={cs.decisions}>
      <div className={cs.header}>
        <h2>Decision</h2>

        <Button
          IconComponent={CloseIcon}
          variant='minimal'
          onClick={() => {
            dispatch?.('CLOSE_DECISIONS')
          }}
        />
      </div>

      <fieldset>
        <legend>Mark this well:</legend>

        <div>
          <input type='radio' id='markPassage' name='decision' value='passage' />
          <label htmlFor='markPassage'>Ready to passage</label>
        </div>

        <div>
          <input type='radio' id='markEmpty' name='decision' value='empty' />
          <label htmlFor='markEmpty'>Empty</label>
        </div>

        <div>
          <input
            type='radio'
            id='markMulticlonal'
            name='decision'
            value='multiclonal'
          />
          <label htmlFor='markMulticlonal'>Multiclonal</label>
        </div>
      </fieldset>

      <div className={cs.buttons}>
        <Button onClick={() => {}} label='Save' type='primary' className={cs.button} />
        <Button
          onClick={() => {}}
          label='Save and go to next'
          type='primary'
          className={cs.button}
        />
      </div>
    </div>
  )
}
