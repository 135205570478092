import { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import { MultiFrontendContext } from '~/core/MultiFrontendContext'

import CulturePage from './CulturePage/CulturePage'
import { LiveCultures } from './LiveCultures/LiveCultures'
import cs from './monitor.scss'

const MonitorRoutes = ({
  frontendContext,
}: {
  frontendContext: MultiFrontendContext
}) => {
  const match = useRouteMatch()

  useEffect(() => {
    if (frontendContext.deploymentMode === 'automation') {
      window.location.href = `${frontendContext.cloudFrontend}/monitor`
    }
  }, [frontendContext])
  if (frontendContext.deploymentMode === 'automation') {
    return <div className={cs.routeErrorContainer}>Redirecting to Monomer Cloud...</div>
  }

  const enabledPages = frontendContext.orgMetadata.enabledPages ?? []
  if (!(enabledPages === 'all' || enabledPages.includes('monitor'))) {
    return (
      <div className={cs.routeErrorContainer}>
        You don't have access to this Culture Monitor page. If this is not expected,
        contact your Monomer support team.
      </div>
    )
  }

  return (
    <div className={cs.monitorRoutes}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/live-cultures`} />}
        />
        <Route
          exact
          path={`${match.path}/live-cultures`}
          render={() => <LiveCultures />}
        />
        <Route
          path={`${match.path}/culture/:cultureId`}
          render={({
            match: {
              params: { cultureId },
            },
          }) => <CulturePage cultureId={cultureId} />}
        />
      </Switch>
    </div>
  )
}

export default MonitorRoutes
